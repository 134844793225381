<template>
  <div v-if="list && list.length > 0" class="vip-box">
    <!--标题 最近直播 -->
    <div class="home-three-title">
      <div class="text">
        <!-- <img class="fourth-img" src="@/assets/img/homeFourth/vip.png" /> -->
        <div class="text-slot name1">
          <slot name="name1">VIP</slot>
        </div>
        <div class="text-slot">
          <slot name="name2">专属</slot>
        </div>
      </div>

      <span class="more" @click="goVipList">查看更多&nbsp;></span>
    </div>
    <!-- 最近直播  内容 -->
    <div class="vip">
      <img class="vip-img" src="@/assets/img/homeFifth/vip.png">

      <div class="vip-class">
        <div v-for="(item, index) in list" :key="index" class="item" @click="banxingke(item)">
          <img class="item-icon" src="@/assets/img/homeFifth/vipIcon.png">
          <img class="item-img" :src="item.img">
          <div class="content">
            <div class="item-title twoEllipsis">{{ item.tiitle }}</div>

            <div class="item-price flex-between-c">
              <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
              <div class="tips">人气值{{ item.clicks ? item.clicks : 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectPageListToIndex, selectAcademyKindzyList } from '@/api/home'

export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [] // 公开课   ifHave: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    goVipList() {
      const routeUrl = this.$router.resolve({
        path: `/VIP/VIPClass`
      })
      window.open(routeUrl.href, '_blank')
    },
    getList() {
      selectPageListToIndex(6, 1, undefined, undefined, undefined, undefined, 3).then((res) => {
        this.list = res.rows
        for (const cur of this.list) {
          if (cur.jfType) {
            cur.jfType = cur.jfType.split(',')
          } else {
            cur.jfType = []
          }
        }
      })
    },
    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "VIP/VIPClassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: 'VIP/VIPClassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.home-three-title {
  width: 1220px;
  padding-bottom: 30px;
  .text {
    -webkit-transform: skew(-12deg);
  }
  .name1 {
    color: #e8b366;
  }
}
// 最近直播  内容
.vip {
  width: calc(1220px + 24px);
  margin-left: calc(50% - 610px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 36px;
  .vip-img {
    width: 287px;
    height: 614px;
    margin-right: 24px;
    flex-shrink: 0;
  }
  .vip-class {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 287px - 24px);
    .item {
      margin-bottom: 24px;
      margin-right: 24px;
      width: 287px;
      height: 295px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      position: relative;

      .item-icon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 80px;
        height: 31px;
      }
      .item-img {
        width: 287px;
        height: 160px;
        background: #c4c4c4;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
      }

      .content {
        padding: 12px 16px;
        background: url('~@/assets/img/homeFifth/vipImg.png');
        background-size: 100% 100%;
        .item-title {
          height: 52px;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5a4629;
          line-height: 26px;
        }
        .item-price {
          margin-top: 20px;
          padding-top: 20px;
          width: 100%;
          border-top: 1px solid rgba(90, 70, 41, 0.05);
          .price {
            font-family: Microsoft YaHei-Regular;
            color: #5a4629;
            font-size: 18px;
            line-height: 18px;
            font-weight: Bold;
            .price-icon {
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
            }
          }
          .tips {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: rgba(153, 153, 153, 0.6);
            line-height: 14px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
