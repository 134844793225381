<template>
  <div class="vip-box" v-if="list && list.length > 0">
    <!--标题 最近直播 -->
    <div class="home-three-title">
      <div class="text fourth-text">
        <img class="fourth-img" src="@/assets/img/homeFourth/vip.png" />
        <div class="text-slot">
          <slot>VIP专属</slot>
        </div>
      </div>
      <span class="more" @click="goVipList">查看更多&nbsp;></span>
    </div>
    <!-- 最近直播  内容 -->
    <div class="vip">
      <img class="vip-img" src="@/assets/img/homeFourth/vipImg.png" />

      <div v-for="(item, index) in list" :key="index" class="item" @click="banxingke(item)">
        <img class="item-bg" src="@/assets/img/homeFourth/VIPBg.png" />
        <img class="item-icon" src="@/assets/img/homeFourth/vipIcon.png" />
        <div class="item-title twoEllipsis">{{ item.tiitle }}</div>
        <div class="item-tips"><span>#8小时极速答疑</span><span class="last-tip">#3大专属服务</span></div>
        <div class="jfType-list">
          <div class="jfType">督学跟踪</div>
          <div class="jfType">定期班会</div>
        </div>
        <div class="item-price flex-between-c">
          <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
          <div class="tips">人气值{{ item.clicks ? item.clicks : 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectPageListToIndex, selectAcademyKindzyList } from '@/api/home'

export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [], //公开课   ifHave: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    goVipList() {
      let routeUrl = this.$router.resolve({
        path: `/VIP/VIPClass`,
      })
      window.open(routeUrl.href, '_blank')
    },
    getList() {
      selectPageListToIndex(6, 1, undefined, undefined, undefined, undefined, 3).then((res) => {
        this.list = res.rows
        for (let cur of this.list) {
          if (cur.jfType) {
            cur.jfType = cur.jfType.split(',')
          } else {
            cur.jfType = []
          }
        }
      })
    },
    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "VIP/VIPClassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: 'VIP/VIPClassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
// 最近直播  内容
.vip {
  width: calc(1220px + 24px);
  margin-left: calc(50% - 610px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  .vip-img {
    width: 598px;
    height: 263px;
    margin-right: 24px;
  }
  .item {
    margin-bottom: 24px;
    margin-right: 24px;
    width: 287px;
    height: 263px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    padding: 46px 16px 0px;
    cursor: pointer;
    .item-bg {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 287px;
      height: 263px;
    }
    .item-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 80px;
      height: 26px;
    }
    .item-title {
      height: 52px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
    }
    .item-tips {
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      margin-top: 20px;
      .last-tip {
        margin-left: 12px;
      }
    }
    .jfType-list {
      display: flex;
      margin-top: 16px;
      .jfType {
        height: 24px;
        line-height: 24px;
        background: #f7f8fc;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        padding: 0px 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9098ab;
      }
      .jfType + .jfType {
        margin-left: 8px;
      }
    }
    .item-price {
      position: absolute;
      bottom: 0px;
      height: 58px;
      left: 16px;
      right: 16px;
      border-top: 1px solid #eeeeee;
      .price {
        font-family: Microsoft YaHei-Regular;
        color: #ff5d51;
        font-size: 18px;
        line-height: 18px;
        font-weight: Bold;
        .price-icon {
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }
      }
      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
  }
}
.home-three-title {
  width: 1220px;
  padding-bottom: 30px;
}
</style>
