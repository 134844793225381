<template>
  <div class="tuijian">
    <!--  课程推荐-->
    <div class="flex-between-c schoole-box">
      <div class="recommend">
        <div
          class="schoole"
          :class="college.pKind == nav.pKind ? 'onCol' : ''"
          @click="collegeClick(nav)"
          v-for="(nav, index) in collegeList"
          :key="index"
        >
          {{ nav.pName }}
        </div>
      </div>
      <span class="more shrink0" @click="goCourseList">查看更多&nbsp;></span>
    </div>
    <!--标题 课程推荐 -->
    <div class="title">
      <div class="choiceLeft" v-for="(kind, index2) in college.kindList" :key="index2" v-if="index2 < 5">
        <div class="choiceLeftText" @click="handleNav(kind)" :class="kindId == kind.pKind ? 'on' : ''">
          {{ kind.pName }}
        </div>
        <div class="choiceLefts" v-show="kindId == kind.pKind"></div>
      </div>
    </div>
    <!-- 课程推荐  内容 -->

    <div class="icon-prop">
      <div class="icon-box" v-if="list.length > 3">
        <i @click="scrol(2)" class="iconfont left icon-anniu_jiantouxiangzuo"></i>
        <i @click="scrol(1)" class="iconfont right icon-anniu_jiantouxiangzuo"></i>
      </div>
      <div class="class-box" id="class-box">
        <div class="class-list">
          <div v-for="(item, index) in list" :key="index" @click.stop="banxingke(item)">
            <!-- 第一个 -->
            <div
              v-if="index == 0"
              class="frist-item"
              :style="`background-image:url(${item.img});background-size: 100% 100%;`"
            >
              <div class="item-tips" v-if="item.curForms">
                {{ item.curForms }}
              </div>
              <div class="item-content">
                <div class="item-title ellipsis">{{ item.tiitle }}</div>
                <div class="item-other">
                  <div class="lastPrice"><span style="font-weight: 400">￥</span>{{ item.price }}</div>
                  <div class="num">人气值{{ item.clicks ? item.clicks : 0 }}</div>
                </div>
              </div>
            </div>
            <!-- 非第一个 -->
            <div v-else class="class-item">
              <img class="item-item" :src="item.img" alt="" />
              <div class="item-content">
                <div class="item-title twoEllipsis">{{ item.tiitle }}</div>
                <div class="item-tips" v-if="item.curForms">
                  {{ item.curForms }}
                </div>
                <div class="item-other">
                  <div class="lastPrice"><span style="font-weight: 400">￥</span>{{ item.price }}</div>
                  <div class="num">人气值{{ item.clicks ? item.clicks : 0 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { selectPageListToIndex, selectAcademyKindzyList } from '@/api/home'
export default {
  components: {},
  data() {
    return {
      list: [], //课程推荐
      collegeList: [],
      college: {}, //当前选择
      kindId: {},
    }
  },
  created() {
    this.getSchool() // 调用所有学院分类
  },
  methods: {
    goCourseList() {
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass`,
      })
      window.open(routeUrl.href, '_blank')
    },
    // 调用所有学院分类
    async getSchool() {
      await selectAcademyKindzyList().then((res) => {
        this.collegeList = res.data
        this.collegeClick(res.data[0])
      })

      this.$forceUpdate()
    },
    /* 选择学yuan */
    collegeClick(college) {
      this.college = college
      this.handleNav(college.kindList[0])
      this.$forceUpdate()
    },
    async handleNav(kind) {
      this.kindId = kind.pKind
      this.getList()
      this.$forceUpdate()
    },
    // 调用课程推荐接口方法
    getList() {
      document.getElementById('class-box').scrollLeft = 0 - document.getElementById('class-box').scrollWidth
      selectPageListToIndex(9, 1, undefined, this.kindId).then((res) => {
        this.list = res.rows
      })
    },

    // 查看更多知识套餐课按钮
    banxingkes(schoole, kind) {
      // this.$router.push(`/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`);
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`,
      })
      window.open(routeUrl.href, '_blank')
    },

    // 知识套餐课详情
    banxingke(item) {
      /*  
      this.$router.push({
        path: "/typeclassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: '/typeclassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    scrol(type) {
      if (type == 1) {
        document.getElementById('class-box').scrollLeft = document.getElementById('class-box').scrollWidth
      } else {
        document.getElementById('class-box').scrollLeft = 0 - document.getElementById('class-box').scrollWidth
      }
    },
  },
}
</script>
<style lang="less" scoped>
.tuijian {
  width: 1220px;
  margin-left: calc(50% - 610px);
}
.more {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  cursor: pointer;
}
// 课程推荐
.schoole-box {
  width: 100%;
  .recommend {
    width: 100%;
    height: auto;
    margin: auto;
    overflow-x: scroll;
    .schoole {
      display: inline-block;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      padding: 0px 18px;
      cursor: pointer;
      background: none;
      height: 36px;
      background: #f1f1f1;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      padding: 0px 16px;
      margin-right: 24px;
      margin-bottom: 24px;
    }
    .onCol {
      background: linear-gradient(134deg, #ff9a51 0%, #ff5e51 100%);
      border-radius: 18px 18px 18px 18px;
      color: #ffffff;
    }
  }
}
// 标题 最新公开课
.title {
  width: 100%;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  // margin-top: 24px;
  margin-bottom: 32px;
  .choiceLeft {
    position: relative;
    padding: 0px 16px 11px;
    margin-left: 8px;
    cursor: pointer;
    .on {
      color: #ff5e51;
    }
    .choiceLefts {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 15px);
      width: 30px;
      height: 3px;
      background: linear-gradient(90deg, #ff5e51 0%, #ff5400 100%);
      border-radius: 2px;
    }
  }
}
.icon-prop {
  width: 1220px;
  position: relative;
  overflow: hidden;
  &:hover {
    .iconfont {
      z-index: 2000;
      display: inline-block !important;
    }
  }
  .iconfont {
    position: absolute;
    font-size: 40px;
    color: rgba(0, 0, 0, 0.3);
    display: none;
    top: calc(50% - 20px);
    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
  .left {
    left: 20px;
  }
  .right {
    right: 20px;
    transform: rotate(180deg);
  }
}
.class-box {
  width: 1220px;
  overflow-x: scroll;
  position: relative;
  z-index: 100;
}
.class-list {
  width: 1460px;
  display: flex;
  flex-wrap: wrap;
  // 单个模块
  .class-item {
    width: 268px;
    height: 315px;
    background: #ffffff;
    margin-bottom: 24px;
    margin-right: 24px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    .allTime {
      position: absolute;
      height: 30px;
      line-height: 30px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px 4px 4px 4px;
      padding: 0px 12px;
      right: 10px;
      top: 121px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    // 图片
    .item-item {
      width: 268px;
      height: 150px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    .item-content {
      padding: 0px 16px;
      .item-title {
        height: 48px;
        margin: 12px 0px 0px;
        font-size: 16px;
        line-height: 24px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
      }
      .item-tips {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9098ab;
        line-height: 12px;
        margin-top: 14px;
      }
      .item-other {
        width: 100%;
        margin: 20px 0px 0px;
        display: flex;
        height: 58px;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eeeeee;
      }
    }
  }
  /* 第一个 */
  .frist-item {
    width: 560px;
    height: 315px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-right: 24px;
    position: relative;
    background-size: 100% 100%;

    .item-tips {
      position: absolute;
      background: #c4c4c4 linear-gradient(134deg, #ff958c 0%, #ff5e51 100%);
      border-radius: 0px 0px 8px 8px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
      top: 0px;
      left: 28px;
      padding: 0px 12px;
      height: 30px;
      line-height: 30px;
    }
    .item-content {
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      padding: 44px 16px 0px;
      height: 80px;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8999999761581421) 100%);
      border-radius: 0px 0px 8px 8px;
      opacity: 1;
      .item-title {
        height: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
      }
      .item-other {
        display: flex;
        flex-shrink: 0;
        height: 18px;
        align-items: center;
        .num {
          color: #ffffff;
        }
      }
    }
  }
  /* 价格 */
  .lastPrice {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ff5e51;
    margin-right: 8px;
    line-height: 18px;
  }
  .num {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
