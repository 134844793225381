<template>
  <div class="tuijian">
    <!--  课程推荐-->
    <div
      v-loading="nav.loading"
      ref="recommendBox"
      class="recommend"
      v-for="(nav, index) in collegeList"
      :key="index"
    >
      <!--标题 课程推荐 -->
      <div class="title" v-if="collegeList && collegeList.length > 0">
        <p class="schoole">{{ nav.pName }}</p>
        <div class="choice">
          <div class="choiceLeft" v-for="(nav2, index2) in nav.kindList" :key="index2" v-show="index2 < 4">
            <div
              class="choiceLeftText"
              @click="handleNav(nav, nav2, index)"
              :class="nav.kind == nav2.pKind ? 'class1' : ''"
            >
              {{ nav2.pName }}
            </div>
            <div class="choiceLefts" v-show="nav.kind == nav2.pKind"></div>
          </div>
          <span class="more" @click="banxingkes(nav.pKind, nav.kind)">MORE&nbsp;></span>
        </div>
      </div>

      <!-- 课程推荐  内容 -->
      <div class="recommende" v-if="collegeList && collegeList.length > 0">
        <div class="collegeBWrap">
          <!-- 单个模块 1-->
          <div class="firstImgBox">
            <img v-if="nav.pKind == 4" src="@/assets/img/homesixth/Group 4097.png" alt="" />
            <img v-else-if="nav.pKind == 16" src="@/assets/img/homesixth/Group 4077.png" alt="" />
            <img v-else-if="nav.pKind == 13" src="@/assets/img/homesixth/Group 4071.png" alt="" />
            <img v-else-if="nav.pKind == 18" src="@/assets/img/homesixth/Group 4063.png" alt="" />
            <img v-else-if="nav.pKind == 17" src="@/assets/img/homesixth/Group 4064.png" alt="" />
            <img v-else-if="nav.pKind == 55" src="@/assets/img/homesixth/Group 4090.png" alt="" />
            <img v-else-if="nav.pKind == 11" src="@/assets/img/homesixth/Group 4084.png" alt="" />
            <img v-else-if="nav.pKind == 7196" src="@/assets/img/homesixth/Group 4098.png" alt="" />
            <img v-else src="@/assets/img/homesixth/Group 4099.png" alt="" />
            <!-- <span
              :style="dealColor(nav.pKind)"
              @click="banxingkes(nav.pKind, nav.kind)"
              >查看更多套餐</span
            > -->
          </div>
          <div
            class="recommendes"
            v-for="(item, index) in nav.projectList && nav.projectList.slice(0, 4)"
            :key="index"
            @click="banxingke(item)"
          >
            <div class="itemtil">{{ item.tiitle }}</div>
            <div class="itemxy">
              {{ item.kindName }} | <span>{{ item.courseNum }}</span> 门课程
            </div>
            <div class="recommendesb">
              <div class="contetn" v-for="(curForms, curindex) in item.curForms" :key="curindex">
                <div class="data-icon" v-if="curindex != 0"></div>
                {{ curForms }}
              </div>
            </div>
            <div class="tealist">
              <div
                v-for="(items, indexs) in item.teacherDtoVos && item.teacherDtoVos.slice(0, 3)"
                :key="indexs"
              >
                <div class="terBox">
                  <el-image class="img" :src="items.terImg" fit="cover"></el-image>
                </div>
                <p>{{ items.nickname }}</p>
              </div>
            </div>
            <div class="heng"></div>
            <div class="recommendesc">
              <span>
                <img src="@/assets/img/homesixth/Frame (1).png" alt="" />
                {{ item.clicks ? item.clicks : 0 }} 人气值</span
              >
              <div>
                <span class="lastPrice">
                  <span style="font-weight: 400; color: red; font-size: 14px">￥</span>{{ item.price }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="collegeBWrapRight">
          <p class="informationTitle">
            相关资讯
            <!-- <img class="icon" src="@/assets/img/Home/tips.png" /> -->
          </p>
          <!-- <span class="titleIcon"></span> -->

          <div
            class="informationContent"
            v-if="nav && nav.mationInterfaceList && nav.mationInterfaceList.length > 0"
          >
            <div
              class="contentItem"
              v-for="(mation, index) in nav.mationInterfaceList"
              :key="index"
              @click="toInformationDetails(mation)"
            >
              <img :src="mation.img" alt="" />
              <div class="box-item">
                <div class="minW80">{{ mation.title | ellipsis(14) }}</div>
                <div class="time">{{ mation.addTime | format('yyyy-MM') }}</div>
              </div>
            </div>
          </div>
          <div class="foxRightCen" v-else>
            <!-- <div class="foxRightCenImg">
              <img src="@/assets/img/Home/noInformationData.png" alt="" />
            </div> -->
            <p>暂无资讯</p>
          </div>
          <div @click="viewInfomation" class="moreInfomation">查看更多资讯 ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { selectPageListToIndex, selectAcademyKindzyList, queryAllArticle } from '@/api/home'
import { selectCoursePages } from '@/api/shaixuan'
export default {
  components: {},
  data() {
    return {
      kindList: [],
      tuijianList: '', //课程推荐
      collegeList: [],
    }
  },
  async created() {
    this.loadselectCurriculumPages() // 调用课程推荐接口方法
    /* this.selectAcademyKindzyList(); */ // 调用所有学院分类
    await selectAcademyKindzyList().then((res) => {
      this.collegeList = res.data
      this.collegeList.forEach((item) => {
        this.$set(item, 'loading', false)
        item.kind = item.kindList[0].pKind
      })
    })
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollToTop)
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll', this.scrollToTop)
    next()
  },
  methods: {
    async handleNav(item, nav2, index) {
      this.collegeList[index].loading = true
      item.kind = nav2.pKind
      await selectCoursePages('', '', 1, 5, 1, nav2.pKind, 4).then((res) => {
        if (res.code == 200) {
          item.projectList = res.msg.details
          for (let cur of item.projectList) {
            if (cur.curForms) {
              cur.curForms = cur.curForms.split(',')
            } else {
              cur.curForms = []
            }
          }
        }
      })
      await queryAllArticle(nav2.pKind, 7, 1).then((res) => {
        item.mationInterfaceList = res.rows
      })
      this.collegeList[index].loading = false
      this.$forceUpdate()
    },
    // 调用课程推荐接口方法
    loadselectCurriculumPages() {
      selectPageListToIndex(10, 1).then((res) => {
        this.tuijianList = res.rows
      })
    },
    // 调用所有学院分类
    async selectAcademyKindzyList(item, index) {
      await selectCoursePages('', '', 1, 5, 1, item.kind, 4).then((res) => {
        if (res.code == 200) {
          item.projectList = res.msg.details
          for (let cur of item.projectList) {
            if (cur.curForms) {
              cur.curForms = cur.curForms.split(',')
            } else {
              cur.curForms = []
            }
          }
        }
      })
      await queryAllArticle(item.kind, 7, 1).then((res) => {
        item.mationInterfaceList = res.rows
      })
      this.collegeList[index].loading = false
      this.$forceUpdate()
    },
    viewInfomation() {
      /* this.$router.push(`/allnews`); */
      let routeUrl = this.$router.resolve({
        path: `/allnews`,
      })
      window.open(routeUrl.href, '_blank')
    },
    dealColor(item) {
      if (item == 4) {
        return 'color: #1e78ff'
      } else if (item == 13) {
        return 'color: #369F5A'
      } else if (item == 16) {
        return 'color: #346CFF'
      } else if (item == 17) {
        return 'color: #E38800'
      } else if (item == 18) {
        return 'color: #FF657C'
      } else if (item == 11) {
        return 'color: #FF6B00'
      } else if (item == 55) {
        return 'color: #5445FF'
      }
    },
    // 查看更多知识套餐课按钮
    banxingkes(schoole, kind) {
      // this.$router.push(`/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`);
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`,
      })
      window.open(routeUrl.href, '_blank')
    },
    toInformationDetails(item) {
      /* this.$router.push({ name: "新闻资讯详情", params: { id: item.id } }); */
      let routeUrl = this.$router.resolve({
        name: '新闻资讯详情',
        params: { id: item.id },
      })
      window.open(routeUrl.href, '_blank')
    },
    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "/typeclassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: '/typeclassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.price,
        },
      })
      window.open(routeUrl.href, '_blank')
    },

    scrollToTop() {
      this.isElementNotInViewport(this.$refs.recommendBox)
    },

    isElementNotInViewport(el) {
      if (el) {
        let arr = []
        el.forEach((item, index) => {
          let rect = el[index].getBoundingClientRect()
          if (
            !(rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0)
          ) {
            if (!this.collegeList[index].isShow) {
              this.collegeList[index].loading = true
              this.selectAcademyKindzyList(this.collegeList[index], index)
              this.collegeList[index].isShow = true
            }
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
// 标题 最新公开课
.title {
  // width: 65%;
  width: 1220px;
  margin: 0px auto 32px;
  margin-top: 16px;
  // height: 64px;
  // border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  .schoole {
    width: auto;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #27323f;
    line-height: 24px;
    cursor: pointer;
    display: block;
  }
  .more {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff5e51;
    cursor: pointer;
  }
}

// 课程推荐
.recommend {
  width: 1220px;
  height: auto;
  margin: auto;
  overflow: hidden;
  .schoole,
  .more {
    flex-shrink: 0;
    cursor: pointer;
  }
  .schoole {
    margin-right: 40px;
  }
  .recommende {
    // width: 66.2%;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    // 单个模块
    .recommendes {
      width: 290px;
      height: 295px;
      background: url('~@/assets/img/homesixth/Group 3339.png');
      margin-bottom: 20px;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
      .itemtil {
        height: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-top: 32px;
        padding-left: 20px;
        padding-right: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      .itemxy {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-top: 16px;
        padding-left: 20px;
        span {
          color: #1061ff;
        }
      }
      .tealist {
        margin-top: 20px;
        padding-left: 20px;
        height: 54px;
        display: flex;
        justify-content: flex-start;
        > div {
          width: 48px;
          height: 54px;
          margin-right: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          .terBox {
            overflow: hidden;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-left: 8px;
            .img {
              width: 32px;
              /* height: 32px;
            border-radius: 50%;
            margin-left: 8px; */
            }
          }

          p {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
            text-align: center;
          }
        }
      }
      .heng {
        width: 250px;
        height: 1px;
        background: #ececec;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 0 auto;
        margin-top: 20px;
      }

      // 图片
      img {
        width: 100%;
        height: 161px;
        border-radius: 8px 8px 0px 0px;
      }

      .recommendesb {
        height: 20px;

        background: #f7f7f7;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        margin: 0px 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;

        color: #999999;
        line-height: 20px;
        padding: 0px 8px;
        display: inline-flex;
        margin-top: 12px;
        .contetn {
          display: flex;
          align-items: center;
        }
        .data-icon {
          background: white;
          width: 4px;
          height: 20px;
          // border-radius: 50%;
          margin: 0px 4px;
        }
      }
      .recommendesc {
        width: 100%;
        margin: 15px 0px 0px;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        > span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          display: flex;
          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            margin-top: 2px;
          }
        }
        .lastPrice {
          font-size: 16px;
          font-family: DIN-Bold;
          font-weight: bold;
          color: #ff4027;
        }
      }
    }
  }
}
.choice {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  height: 46px;
  line-height: 46px;
  align-items: center;
  // border-bottom: 1px solid #ddd;
  // margin-bottom: 24px;
  .more {
    position: absolute;
    right: 0;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
  // 密码登录
  .choiceLeft {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
    position: relative;
    .choiceLeftText {
      width: auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7b8196;
      cursor: pointer;
      // line-height: 64px;
    }
    .class1 {
      color: #1061ff;
      cursor: pointer;
    }
    .choiceLefts {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 30);
      width: 30px;
      height: 3px;
      background: #1061ff;
      border-radius: 2px;
    }
  }
  // 分割线
  .choices {
    width: 1px;
    height: 16px;
    background: #dddddd;
    margin-bottom: 7px;
  }
}
.firstImgBox {
  width: 600px;
  height: 295px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: relative;
  // height: 319px;
  // width: 287px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  img {
    width: 600px;
    height: 295px;
  }
  span {
    width: 160px;
    height: 40px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 0.6;
    // color: #1e78ff;
    cursor: pointer;
    position: absolute;
    bottom: 35px;
    left: 20%;
    text-align: center;
    line-height: 40px;
  }
}
.collegeBWrap {
  display: flex;
  width: calc(100% - 287px);
  flex-wrap: wrap;
}
.collegeBWrapRight {
  background: #ffffff;
  border-radius: 8px;
  width: 287px;
  position: absolute;
  top: 0px;
  bottom: 20px;
  right: 0px;
  overflow: hidden;
}
.informationTitle {
  position: relative;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
  line-height: 16px;
  margin-top: 20px;
  margin-left: 20px;
  height: 31px;
  margin-bottom: 8px;
}
.titleIcon {
  display: inline-block;
  width: 25px;
  border-bottom: 3px solid red;
  border-radius: 28px;
  height: 20px;
  margin-left: 6%;
}
.informationContent {
  padding: 10px 20px 0px;
  height: 100%;
  padding-bottom: calc(62px + 40px);
  scrollbar-width: none;
  overflow-y: scroll;
  .contentItem {
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;
    img {
      width: 80px;
      height: 53px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      flex-shrink: 0;
    }
    .box-item {
      width: 100%;
      .minW80 {
        width: 100%;
        height: 44px;
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        padding-top: 12px;
      }
      .time {
        width: 100%;
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        text-align: right;
        margin-top: -3px;
      }
    }
  }
}
.minW80 {
  min-width: 80px;
}
.moreInfomation {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #eee;
  padding: 16px 0;
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #fff;
}
.cacelPrice {
  font-size: 16px !important;
  text-decoration: line-through;
}
.foxRightCen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;

  width: 100%;
  // min-height: 580px;
  // padding: 180px 0px 20px;
  // background: #ffffff;
  margin: 50px 0;
  .foxRightCenImg {
    // margin: auto;
    text-align: center;
    // margin-bottom: 54px;
    img {
      width: 92px;
      height: 91px;
    }
  }
  p {
    // margin: auto;
    text-align: center;
    width: auto;
    height: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
}
.point {
  width: 5px;
  height: 5px;
  background-color: #ff7b1c;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}
.ml5 {
  margin-left: 5px;
}
.icon {
  position: absolute;
  bottom: 0px;
  left: 23px;
  width: 18px;
  height: 6px;
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
