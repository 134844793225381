<template>
  <div class="bannerImg">
    <!--  <div class="swipersRight"><swipers :imgList="imgList"></swipers></div> -->
    <el-carousel height="420px" class="swImg">
      <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer">
        <img :src="item.img" @click="nav(item)">
      </el-carousel-item>
    </el-carousel>
    <!---->
    <div class="swipersLefts" @mouseleave="swipersLeftsa()">
      <!-- 自学考试 -->
      <div class="list">
        <div
          v-for="(item, index) in collageList"
          :key="index"
          class="item"
          :class="kindIndex && kindIndex.pKind == item.pKind ? 'on' : ''"
          @mouseenter="swipersLeftsa(item)"
        >
          <div class="item-title">
            <span class="elp" @click="zhuanYeXqs(undefined, undefined, item.pKind)">{{ item.pName }}</span>
            <span class="iconfont icon-a-Group19" />
          </div>
          <!-- 内容0 -->
          <div class="item-content">
            <div
              v-for="(itmse, indesx) in item.kindList"
              v-show="indesx < 2"
              :key="indesx"
              class="item-content-name elp"
            >
              {{ itmse.pName }}
            </div>
          </div>
        </div>
      </div>
      <!-- -->
      <div v-if="kindIndex" class="wrap" @mouseenter="swipersLeftsa(kindIndex)" @mouseleave="swipersLeftsa()">
        <div v-for="(itmse, indesx) in kindIndex.kindList" :key="indesx">
          <div class="title" @click="projectDetail(itmse)">{{ itmse.pName }}</div>
          <div class="wraps">
            <div
              v-for="(zy, zyIndex) in itmse.zyList"
              :key="zyIndex"
              class="wrapss"
              @click="zhuanYeXqs(zy.zKind, zy.zId, kindIndex.pKind)"
            >
              <div class="name">
                {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import swipers from '@/components/swipers.vue'
import { selectKindAllCollage, getSurfaceList } from '@/api/home'
import { getDomain } from '@/api/cookies'
export default {
  components: { swipers },
  data() {
    return {
      imgList: [],
      kindIndex: null,
      collageList: {},
      domain: null
    }
  },
  created() {
    this.domain = getDomain()
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg
      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
  },
  methods: {
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole) {
      /* this.$router.push({
        path: `/allcurriculum/typeclass?kind=${kind ? kind : ""}&zId=${zId ? zId : ""}&schoole=${
          schoole ? schoole : ""
        }`,
      }); */
      const routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?kind=${kind || ''}&zId=${zId || ''}&schoole=${
          schoole || ''
        }`
      })
      window.open(routeUrl.href, '_blank')
    },

    projectDetail(item) {
      this.$router.push({
        path: `/projectChannelPage`,
        query: {
          id: item.pKind,
          schoolId: item.pParentid
        }
      })
    },

    // 调用轮播图导航接口方法
    loadgetTenantidNavigation() {
      selectKindAllCollage().then((res) => {
        if (res.code == 0) {
          this.collageList = res.data
        } else {
          this.collageList = []
        }
      })
    },
    // 自学考试滑入
    swipersLeftsa(item) {
      // if (item) {
      this.kindIndex = item || null
      // }
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImg {
  width: 100%;
  height: 420px;
  position: relative;
  // 盒子右
  .swipersRight {
    width: 100%;
    height: 420px;
    cursor: pointer;
  }
}
/* 轮播图 */
/deep/.swImg {
  width: 100%;
  height: 420px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .el-carousel__item {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .el-carousel__arrow--left {
    left: calc(50% - 610px + 240px + 40px);
  }
  .el-carousel__arrow--right {
    right: 350px;
  }
  .el-carousel__arrow {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.4);
    i {
      font-size: 30px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  img {
    /* max-width: 100%; */
    height: 420px;
    margin: auto;
  }
}

// 缩宽
.swipersLefts {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;
  .list {
    height: 100%;
    width: 240px;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: #fff;
    // 自学考试
    .item {
      width: 100%;
      /* padding: 15px 20px;
      border-left: 4px solid #fff; */
      padding: 9px 20px;
      border-left: 4px solid #fff;
      // border-bottom: 1px solid #eee;
      .item-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
        }
        .icon-a-Group19 {
          font-size: 16px;
          color: #dddddd;
          transform: scale(0.5);
          -webkit-transform: scale(0.5);
          -o-transform: scale(0.5);
          -ms-transform: scale(0.5);
          -moz-transform: scale(0.5);
          &::after {
            width: 6px;
            height: 8px;
          }
        }
      }
      // 内容
      .item-content {
        width: 100%;
        margin-top: 7px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        scrollbar-width: none;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #666666;
        line-height: 12px;
        height: 12px;
        .item-content-name {
          min-width: calc(40% - 8px);
          max-width: calc(50% - 8px);
          margin-right: 16px;
          flex-shrink: 0;
          white-space: nowrap; //强制不换行
          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
    .on {
      border-left: 4px solid #ff5e51;
      background: #f7f7f7;
    }
  }
}
.wrap {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 240px;
  width: 700px;
  background: #ffffff;
  z-index: 99999;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0px 2px 4px 1px rgba(153, 153, 153, 0.20000000298023224);
  border-radius: 0px 8px 8px 0px;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei-Bold;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    padding-top: 30px;
  }
  .wraps {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .wrapss {
      width: calc(100% / 5);
      margin-top: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      text-align: left;
      &:hover {
        .name {
          width: fit-content;
          color: #ff5d51;
          border-bottom: 1px solid #ff5d51;
        }
      }
    }
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
