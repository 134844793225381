<template>
  <div v-if="openClasslist.length > 0" class="seventhOpen">
    <!-- 公开课 -->
    <div class="til">
      <div>公开课</div>
      <div @click="saveImg('/seventhPage/publicClass')">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="classlist box-page">
      <div class="courseList">
        <template v-for="(item, index) in openClasslist">
          <div v-if="index <= 3" :key="item.id" class="openClassTime">
            <!-- <div class="openClasslistTime" />
          <div class="liveTime">
            <img src="../../style/image/lives.png" alt="">
            <span>{{ item.zbBeginTimeNewCh }}</span>
          </div> -->
            <div class="courstItem" :class="item.state == 1 ? 'liveIng' : ''" @click="goDetail(item)">
              <div class="openClassImg">
                <el-image class="duan" :src="item.pcPictureUrl" fit="cover" style="width: 100%" />
                <el-image class="chang" :src="item.longPicture" fit="cover" style="height: 240px" />
              </div>
              <div class="" style="width: 282px;">
                <div class="contentItem">
                  <div class="titleItem">{{ item.name }}</div>
                  <div class="contentItemFooter">
                    <div class="contentItemActive">
                      <el-image class="contentItemActive_img" :src="require('@/assets/img/Home/livetime1.png')" />
                      <span class="contentItemActive_day">{{
                        item.zbBeginTimeNew
                      }}</span>
                      <span class="contentItemActive_time" style="margin-left: 5px;">{{
                        item.zbBeginTimeDay
                      }}</span>
                    </div>

                    <div class="but" round> <img src="~@/assets/img/xinren/l7732.png">免费学习</div>
                    <!-- <el-button class="butbig" round>免费学习</el-button> -->
                  </div>
                </div>

                <!-- <div class="timeItem">
              <i class="iconfont icon-daishouhuo" />
              <span>直播时间：{{ item.zbBeginTime }}</span>
            </div> -->

                <!-- <div class="contentItem_tips">
                <p><span style="margin: 0 10px 0 0;font-size: 16px;">•</span>海量硬核干货 掌握考试考点</p>
                <p><span style="margin: 0 10px 0 0;font-size: 16px;">•</span>免费直播 吃透考试课题</p>
                <p><span style="margin: 0 10px 0 0;font-size: 16px;">•</span>考点分析 备考指导</p>
              </div> -->

                <!-- <div class="timeLine" /> -->
                <!-- <el-divider /> -->
                <!-- <div class="teacherItem">
              <div class="flex-c">
                <img class="itemImg" :src="item.lecturerUrl">
                <div class="itemBox">讲师：{{ item.lecturerName }}</div>
              </div>
            </div> -->
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
import {
  selectPageListToIndex,
  getMYTenantKindZyList,
  selectNewPageListToIndex
} from '@/api/home'
// import { selectPageList } from '@/api/task/answerClient'
import { getClassCourseListById } from '@/api/mykecheng'
import { getInfo } from '@/api/cookies'
import {
  selectKindAllCollage
  // getSurfaceList,
  // selectCoursePages
} from '@/api/home'
export default {
  components: {},
  data() {
    return {
      openClasslist: [], // 公开课
      collageList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null
      },
      loadingBtn: false,
      unPurchase: true,
      showMore: '在线题库',
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: 'first'
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.searchClick()
    await this.getKind()
  },
  methods: {
    // 项目专业
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code === 0) {
          this.collageList = res.data
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = []
        }
      })
    },
    show(item) {
      item.showlong = true
    },
    hidden(item) {
      item.showlong = false
    },
    // 跳转试卷包和资料详情页
    godateDetail(row) {
      this.showMore = row.label
    },
    goMore() {
      if (this.showMore == '在线题库') {
        this.saveImg('/seventhPage/quesTionList')
      }
      if (this.showMore == '干货资料') {
        this.saveImg('/seventhPage/dataPackage')
      }
    },
    handleClick() {
      //  
    },
    school(val) {
      if (val) {
        this.search.kindList = val
        this.search.kindName = val?.pName
        this.search.kind = val?.pKind
      } else {
        this.search.kind = null
      }

      this.searchClick()
    },

    searchClick() {
      this.getOpenClass()
      this.getClassList()
      // this.selectPageListToIndex()
      this.selectPageListToIndexRecommend()
    },
    /* =========实用信息========== */
    /* 项目修改 */
    getDetail(kind) {
      //  if (this.search.kind == kind.pKind) {
      //   this.search.kind = null;
      //   this.search.kindName = null;
      // } else
      this.search.kind = kind.pKind
      this.search.kindName = kind.pName
      // }
      this.getExmInfo()
      // this.getPackageList()
    },
    /* =======公开课================= */
    getOpenClass() {
      const data = {
        pageNum: 1,
        pageSize: 5,
        kindId: this.search.kind,
        zStage: this.search.stateName,
        zyId: this.search.zyId,
        /* state: 3, */
        isShow: 1
      }
      know.getNewPublicClassList(data).then((res) => {
        this.openClasslist = res.rows.filter((item, index) => {
          const month = new Date(item.zbBeginTime).getMonth() + 1
          const date = new Date(item.zbBeginTime).getDate()
          const hour = new Date(item.zbBeginTime).getHours()
          const minute = new Date(item.zbBeginTime).getUTCMinutes()
          const nowMonth = new Date().getMonth() + 1
          const nowDate = new Date().getDate()
          if (nowMonth == month && nowDate == date) {
            // item.zbBeginTimeNew = `今天` //
            item.zbBeginTimeNew = `${month <= 9 ? '0' + month : month}/${date <= 9 ? '0' + date : date
              }` //
            item.zbBeginTimeNewCh = `${month <= 9 ? '0' + month : month}月${date <= 9 ? '0' + date : date
              }日`
            item.zbBeginTimeDay = ` ${hour <= 9 ? '0' + hour : hour}:${minute <= 9 ? '0' + minute : minute
              }`
          } else {
            item.zbBeginTimeNew = `${month <= 9 ? '0' + month : month}/${date <= 9 ? '0' + date : date
              }` //
            item.zbBeginTimeNewCh = `${month <= 9 ? '0' + month : month}月${date <= 9 ? '0' + date : date
              }日`
            item.zbBeginTimeDay = `${hour <= 9 ? '0' + hour : hour}:${minute <= 9 ? '0' + minute : minute
              }`
          }
          return index < 5
        })
      })
    },
    goDetail(item) {
      if (this.userInfo) {
        if (item.state === 2 && !item.playbackUrl) {
          this.$message({
            type: 'error',
            message: '回放生成中'
          })
        } else {
          this.addRecord(item)
          const routeUrl = this.$router.resolve({
            path: '/publicClass/publicClassDetail',
            query: {
              id: item.id
            }
          })
          window.open(routeUrl.href, '_blank')
        }
      } else {
        Bus.$emit('toLoginPage', true)
      }
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null
      }
      know.addPublicityClassStudentRecord(data).then((res) => { })
    },
    /* =======知识套餐============ */
    // 获取知识套餐列表
    getClassList() {
      selectPageListToIndex(
        6,
        1,
        undefined,
        this.search.kind,
        undefined,
        undefined,
        3
      ).then((res) => {
        for (const item of res.rows) {
          if (item.curForms) {
            item.curForms = item.curForms.split(',')
          }
        }
        this.classlist = res.rows
        for (const item of this.classlist) {
          const data =
            item.teacherDtoVos && item.teacherDtoVos.length > 0
              ? item.teacherDtoVos[0]
              : null
          item.tImg = data ? data.terImg : ''
          item.terName = data ? data.userName : ''
        }
      })
    },
    // 获取项目专业
    async getKind() {
      await getMYTenantKindZyList().then((res) => {
        if (res.code == 0) {
          this.zhuanList = res.data
          const oldList = []
          this.zhuanList.filter((item) => {
            if (item.pKind == 3) {
              item.num = 1
              item.pName = '普通专升本'
              oldList.push(item)
            }
            if (item.pKind == 1) {
              item.num = 2
              item.pName = '自学考试'
              oldList.push(item)
            }
            if (item.pKind == 9) {
              item.num = 3
              item.pName = '普通考研'
              oldList.push(item)
            }
            if (item.pKind == 6) {
              item.num = 4
              item.pName = '教师资格证'
              oldList.push(item)
            }
            if (item.pKind == 7) {
              item.num = 5
              item.pName = '计算机等级'
              oldList.push(item)
            }
            if (item.pKind == 5) {
              item.num = 6
              item.pName = '英语等级'
              oldList.push(item)
            }
          })
          const len = oldList.length
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < i; j++) {
              if (oldList[j].num > oldList[i].num) {
                const temp = oldList[j]
                oldList[j] = oldList[i]
                oldList[i] = temp
              }
            }
          }
          this.majorList = oldList
        } else {
          this.zhuanList = []
        }
      })
    },
    // 点击图片跳转到知识套餐详情页
    imgxq(index, item) {
      const routeUrl = this.$router.resolve({
        name: '知识套餐课详情',
        query: {
          id: item.id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    /* ============VIP专属班列表============ */
    selectPageListToIndexRecommend() {
      selectNewPageListToIndex(
        6,
        1,
        // this.pageSize,
        // this.pageNum,
        // this.search.school,
        undefined,
        this.search.kind,
        this.search.zyId,
        undefined,
        3 /* productLine 3专属线 */,
        undefined,
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {
        this.RecommendClassList = res.rows
        this.total = res.total
      })
    },
    /* 获取试卷包列表 */
    // selectNewPaperListJxu() {
    //   selectNewPaperListJxu
    // }
    /* 点击进入专属班详情 */
    goDetails(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // selectPageListToIndex() {
    //   selectPageListToIndex(
    //     6,
    //     1,
    //     // this.pageSize,
    //     // this.pageNum,
    //     this.search.school,
    //     // undefined,
    //     this.search.kind,
    //     this.search.zyId,
    //     undefined,
    //     3, /* productLine 3专属线 */
    //     this.search.areaId == 1 ? undefined : this.search.areaId
    //   ).then((res) => {
    //     this.exclusiveClassList = res.rows
    //     this.total = res.total
    //   })
    // },
    // 点击立即报名按钮 跳转支付页
    async zhifu(item) {
      if (this.userInfo) {
        const res = await getClassCourseListById(this.userInfo.id, 3)
        res.rows.filter((items) => {
          if (items.coursesId == item.id) {
            this.$message({
              message: '当前已拥有此课程，无法下单',
              type: 'error'
            })
            this.unPurchase = false
          }
        })
        if (this.unPurchase) {
          this.$router.push(`/orders/classOrder?classId=${item.id}`)
        } else {
          this.unPurchase = true
        }
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.seventhOpen {
  width: 1200px;
  margin: 15px auto 15px;
  padding: 20px;
  // margin-top: 138px;
  // margin-bottom: 15px;
  background-image: url("../../assets/img/Home/openclassBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 100%;
    height: 24px;
    // margin: 14px auto 0px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  /* 公开课 */
  .classlist {
    width: 100%;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 9px;
    padding: 11px;

    .courseList {
      overflow: hidden;
      margin: -6px !important;

      .openClassTime {
        float: left;
        width: 563px;
        background-color: #f5f7fa;
        border-radius: 9px;
        overflow: hidden;
        margin: 6px;

        .courstItem {
          display: flex;

          .contentItem {
            .titleItem {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 23px;
              height: 50px;
              margin: 20px 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }

          .contentItemFooter {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .contentItemActive {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 32px;

              .contentItemActive_img {
                width: 14px;
                height: 14px;
                margin: -1px 5px 0 0;
                vertical-align: middle;
              }
            }

            .but {
              display: flex;
              justify-content: center;
              align-items: center;

              // padding-right: 23px;
              z-index: 1;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              // width: 80px;
              // padding-left: 11px;
              box-shadow: 0px 2px 10px 1px rgba(0, 170, 140, 0.3);
              height: 32px;
              line-height: 0px;
              color: #fff;
              border-color: #00ab8c;
              background-color: #00ab8c;
              padding: 9px 13px;
              border-radius: 20px;
              cursor: pointer;

              img {
                display: block;
                width: 13px;
                margin-right: 4px;
              }
            }
          }

          .openClassImg {
            width: 250px;
            height: 140px;
            margin-right: 14px;

            .duan {
              width: 100%;
              height: 100%;
              border-radius: 0 9px 9px 0;
            }
          }
        }
      }

      // display: flex;
      // flex-wrap: wrap;
      // justify-content: start;
      // .openClassTime {
      //   position: relative;
      //   display: flex;
      //   width: 224px;
      //   margin-right: 20px;
      //   &:last-child {
      //   margin-right: 0;
      //   .openClasslistTime{
      //       width: 100%;
      //     }
      //     }
      //   height: 30px;
      //   .openClasslistTime {
      //  width: 282px;
      //  height: 1px;
      //  background: #e7e2e2;
      // // border: 1px dotted #afafaf;
      // opacity: 1;
      // position: absolute;
      // left: 0;
      // right: 0;
      // top: 13px;
      // // z-index:1;
      // //  margin:30px 10px 0 17px;
      //  }
      //   .liveTime {
      //     display: flex;
      //     justify-content:center;
      //     background-color: #f5f5f5;
      //     font-size: 14px;
      //     width: 49%;
      //     height: 20px;
      //     line-height: 20px;
      //     position: absolute;
      //     left: 0;
      //     right: 0;
      //     top: 4px;
      //     z-index:1;
      //     margin: 0 auto ;
      //     span {
      //       margin-right: 5px;
      //     }

      //     img {
      //       // margin: auto;
      //       margin: 3px 3px 0;
      //       width: 14px;
      //       height: 14px;
      //       margin-right: 5px;
      //       vertical-align: middle;
      //     }
      //   }
      // }
      // margin-top: 12px;
      /* 正在直播 */
      .liveIng {
        .timeItem {
          color: #4a6af0 !important;

          .iconfont {
            color: #4a6af0 !important;
          }
        }
      }

      // .courstItem {
      //   position: relative;
      //   cursor: pointer;
      //   width: 224px;
      //   height: 320px;
      //   // z-index: 9;
      //   background: #fff;
      //   // overflow: hidden;
      //   border-radius: 8px 8px 8px 8px !important;
      //   opacity: 1;
      //   margin: 0 20px 24px 0px;
      //   // transition: all 300ms;
      //   &:last-child {
      //     margin-right: 0px;
      //   }
      //   &:hover {
      //     z-index:2;
      //     box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
      //     width: 278px;
      //     transform: translate(-27px,-20px);
      //     // transform: scale(1.14);
      //     .but {
      //       display: none;
      //       width: 105px;
      //     //  padding-left: 21px;
      //     box-shadow: 0 1px 5px 5px rgba(0, 170, 140, 0.1);
      //     transform: translate(-50%-16px,35px);
      //     }
      //             .butbig {
      //       display: block;
      //       // width: 105px;
      //     //  padding-left: 21px;
      //     transform: translate(-50%-16px,35px);
      //     }
      //   .contentItem {
      //     z-index: 12;
      //     display: block;
      //     width: 278px;
      //     border-radius: 0 0 8px 8px;
      //     transform: translateY(-135px);
      //   }
      //     .liveTime {
      //     transform: scale(1,1);
      //     }
      //     .openClassImg {
      //       z-index: 12;
      //       width: 278px;
      //       .duan {
      //         height: 184px;
      //         display: block;
      //       }
      //       .chang {
      //         display: none;
      //       }
      //     }
      //   }
      //   .openClassImg {
      //   border-radius: 8px 8px 0px 0px !important;
      //   width: 224px;
      //   height: 240px;
      //   overflow: hidden;
      //   .duan {
      //     display: none;
      //   }
      //   .chang {
      //     display: block;
      //   }
      //   }
      //   .timeLine {
      //     width: 100%;
      //     height: 1px;
      //     padding: 0 10px;
      //     background-color: #cecece;
      //     // margin-bottom: 10PX;
      //   }
      //   .itemImg {
      //     flex-shrink: 0;
      //     width: 30px;
      //     height: 30px;
      //     background: #c4c4c4;
      //     opacity: 1;
      //     border-radius: 50%;
      //     margin-right: 5px;
      //   }
      //   .el-divide {
      //     height: 1px;
      //     background: #dee1ef;
      //     margin: 20px 0px;
      //   }
      //   .contentItemActive {
      //     height: 80px;
      //     padding-top: 16px;
      //     .contentItemActive_day {
      //       margin-left: 15px;
      //       height: 24px;
      //       font-size: 18px;
      //       line-height: 24px;
      //       font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      //       font-weight: bold;
      //       color: #333333;
      //     }
      //     .contentItemActive_time {
      //     height: 16px;
      //     font-size: 12px;
      //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //     font-weight: 400;
      //     color: #999999;
      //     }
      //   }
      //   .but {
      //     position: absolute;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     padding-right: 23px;
      //     right: 16px;
      //     bottom: 24px;
      //     z-index: 1;
      //     font-size: 14px;
      //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //     font-weight: 400;
      //     width: 80px;
      //     // padding-left: 11px;
      //     box-shadow: 0px 2px 10px 1px rgba(0, 170, 140, 0.3);
      //     height: 32px;
      //     line-height: 0px;
      //     color:#fff;
      //     border-color:#00AB8C;
      //     background-color: #00AB8C;
      //       }
      //       .butbig {
      //     position: absolute;
      //     display: none;
      //     padding-right: 23px;
      //     right: 0px;
      //     bottom: 4px;
      //     z-index: 1;
      //     font-size: 14px;
      //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //     font-weight: 400;
      //     width: 120px;
      //     box-shadow: 0px 2px 10px 1px rgba(0, 170, 140, 0.3);
      //     // padding-left: 11px;
      //     height: 38px;
      //     line-height: 0px;
      //     color:#fff;
      //     border-color:#00AB8C;
      //     background-color: #00AB8C;
      //       }
      //   .contentItem {
      //     display: none;
      //     height: 178px;
      //     width: 278px;
      //     background: #fff;
      //      padding: 12px 16px 12px;
      //      .contentItem_tips {
      //       // margin: 0px auto 0;
      //       // margin-left: 7px;
      //       width: 192px;
      //       height: 83px;
      //       // padding: 6px 0;
      //       // background: #f5f5f5;
      //     // box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
      //       // border-radius: 8px 8px 8px 8px;
      //       opacity: 1;
      //       p {
      //         width: 100%;
      //         height: 18px;
      //         margin-bottom: 9px;
      //         font-size: 12px;
      //         font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //         font-weight: 400;
      //         color: #666666;
      //         span {
      //           color: #00AB8C;
      //         }
      //       }
      //      }
      //     .timeItem {
      //       font-size: 12px;
      //       font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //       font-weight: 400;
      //       color: #4a6af0;
      //       line-height: 12px;
      //       margin-bottom: 16px;
      //       .iconfont {
      //         font-size: 12px;
      //         color: #4a6af0;
      //         margin-right: 4px;
      //       }
      //     }
      //     .titleItem {
      //       height: 24px;
      //       font-size: 14px;
      //       font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //       font-weight: 400;
      //       color: #333333;
      //       line-height: 20px;
      //       margin-bottom: 2px;
      //       // margin-top: 12px;
      //     }
      //     .teacherItem {
      //       margin-top: 10px;
      //       display: flex;
      //       align-items: center;
      //       justify-content: space-between;
      //       .terImg {
      //         width: 14px;
      //         height: 14px;
      //         margin-right: 4px;
      //         flex-shrink: 0;
      //       }
      //       .itemBox {
      //         font-size: 12px;
      //         font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //         font-weight: 400;
      //         // color: #f5f5f5;
      //         line-height: 12px;
      //       }
      //     }
      //   }
      // }
    }
  }
}</style>
<style lang="less">.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}