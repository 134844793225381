<template>
  <div class="seventhOpen">
    <!-- 公开课 -->
    <div class="til">
      <div class="title">
        公开课
        <img src="@/assets/img/partner/corner.png" alt="" />
      </div>
      <div @click="goPage">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="classlist box-page">
      <div class="courseList">
        <div v-for="item in openClasslist" :key="item.id" class="openClassTime">
          <div class="openClasslistTime" />
          <div class="liveTime">
            <img src="@/assets/img/partner/open.png" alt="" />
            <span>{{ item.zbBeginTimeNewCh }}</span>
          </div>
          <div class="courstItem" :class="item.state == 1 ? 'liveIng' : ''" @click="goDetail(item)">
            <div class="openClassImg">
              <el-image class="chang" :src="item.pcPictureUrl" fit="cover" style="width: 100%" />
              <div v-if="item.state == 1" class="living">
                <img src="@/assets/img/partner/living.png" alt="">
              </div>
              <!-- <el-image
                class="chang"
                :src="item.longPicture"
                fit="cover"
                style="height: 240px"
              /> -->
            </div>
            <div class="contentItemActive">
              <p style="margin-bottom: 9px">
                <span class="contentItemActive_day twoEllipsis">{{
                  item.name
                }}</span>
              </p>
              <div style="height: 16px">
                <!-- <el-image
                  class="contentItemActive_img"
                  style="margin-left: 15px"
                  :src="require('@/assets/img/homeSeventh/livetime.png')"
                /> -->
                <span class="contentItemActive_time">直播时间：{{ item.zbBeginTime.substring(0, item.zbBeginTime.length - 3)
                }}</span>
              </div>
              <div class="line"></div>
              <div class="dis_flex_start" style="
                  margin-top: 14px;
                  align-items: center;
                  padding-left: 18px;
                ">
                <div class="teaImg">
                  <img :src="item.lecturerUrl" alt="" />
                </div>
                <span class="teaName">{{ item.lecturerName }}</span>
              </div>
            </div>
            <el-button class="but" :class="[
              item.state == 2 ? 'end' : '',
              item.state == 1 ? 'live' : '',
            ]" round>{{ item.stateb }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import "@/assets/css/common.css";
import { Know } from "@/api/know";
const know = new Know();
import {
  selectPageListToIndex,
  getMYTenantKindZyList,
  selectNewPageListToIndex,
} from "@/api/home";
// import { selectPageList } from '@/api/task/answerClient'
import { getClassCourseListById } from "@/api/mykecheng";
import { getInfo } from "@/api/cookies";
import {
  selectKindAllCollage,
  // getSurfaceList,
  // selectCoursePages
} from "@/api/home";
export default {
  components: {},
  data() {
    return {
      openClasslist: [], // 公开课
      collageList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null,
      },
      loadingBtn: false,
      unPurchase: true,
      showMore: "在线题库",
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: "first",
    };
  },
  async created() {
    this.userInfo = await getInfo();
    this.searchClick();
    await this.getKind();
  },
  methods: {
    // 项目专业
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code === 0) {
          this.collageList = res.data;
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = [];
        }
      });
    },
    show(item) {
      item.showlong = true;
    },
    hidden(item) {
      item.showlong = false;
    },
    // 跳转试卷包和资料详情页
    godateDetail(row) {
      this.showMore = row.label;
    },
    goMore() {
      if (this.showMore == "在线题库") {
        this.saveImg("/seventhPage/quesTionList");
      }
      if (this.showMore == "干货资料") {
        this.saveImg("/seventhPage/dataPackage");
      }
    },
    handleClick() {
      // console.log('111')
    },
    school(val) {
      if (val) {
        this.search.kindList = val;
        this.search.kindName = val?.pName;
        this.search.kind = val?.pKind;
      } else {
        this.search.kind = null;
      }

      this.searchClick();
    },

    searchClick() {
      this.getOpenClass();
      this.getClassList();
      // this.selectPageListToIndex()
      this.selectPageListToIndexRecommend();
    },
    /* =========实用信息========== */
    /* 项目修改 */
    getDetail(kind) {
      //  if (this.search.kind == kind.pKind) {
      //   this.search.kind = null;
      //   this.search.kindName = null;
      // } else
      this.search.kind = kind.pKind;
      this.search.kindName = kind.pName;
      // }
      this.getExmInfo();
      // this.getPackageList()
    },
    /* =======公开课================= */
    getOpenClass() {
      const data = {
        pageNum: 1,
        pageSize: 5,
        kindId: this.search.kind,
        zStage: this.search.stateName,
        zyId: this.search.zyId,
        /* state: 3, */
        isShow: 1,
      };
      know.getNewPublicClassList(data).then((res) => {
        this.openClasslist = res.rows.filter((item, index) => {
          const month = new Date(item.zbBeginTime).getMonth() + 1;
          const date = new Date(item.zbBeginTime).getDate();
          const hour = new Date(item.zbBeginTime).getHours();
          const minute = new Date(item.zbBeginTime).getUTCMinutes();
          const nowMonth = new Date().getMonth() + 1;
          const nowDate = new Date().getDate();
          if (item.state == 2) {
            item.stateb = "已结束";
          } else if (item.state == 1) {
            item.stateb = "进入直播";
          } else {
            item.stateb = "未开始";
          }
          if (nowMonth == month && nowDate == date) {
            // item.zbBeginTimeNew = `今天` //
            item.zbBeginTimeNew = `${month <= 9 ? "0" + month : month}/${date <= 9 ? "0" + date : date
              }`; //
            item.zbBeginTimeNewCh = `${month <= 9 ? "0" + month : month}月${date <= 9 ? "0" + date : date
              }日`;
            item.zbBeginTimeDay = ` ${hour <= 9 ? "0" + hour : hour}:${minute <= 9 ? "0" + minute : minute
              }`;
          } else {
            item.zbBeginTimeNew = `${month <= 9 ? "0" + month : month}/${date <= 9 ? "0" + date : date
              }`; //
            item.zbBeginTimeNewCh = `${month <= 9 ? "0" + month : month}月${date <= 9 ? "0" + date : date
              }日`;
            item.zbBeginTimeDay = `${hour <= 9 ? "0" + hour : hour}:${minute <= 9 ? "0" + minute : minute
              }`;
          }
          return index < 4;
        });
      });
    },
    goDetail(item) {
      if (this.userInfo) {
        if (item.state === 2 && !item.playbackUrl) {
          this.$message({
            type: "error",
            message: "回放生成中",
          });
        } else {
          this.addRecord(item);
          const routeUrl = this.$router.resolve({
            path: "/publicClass/publicClassDetail",
            query: {
              id: item.id,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      } else {
        Bus.$emit("toLoginPage", true);
      }
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      };
      know.addPublicityClassStudentRecord(data).then((res) => { });
    },
    /* =======知识套餐============ */
    // 获取知识套餐列表
    getClassList() {
      selectPageListToIndex(
        6,
        1,
        undefined,
        this.search.kind,
        undefined,
        undefined,
        3
      ).then((res) => {
        for (const item of res.rows) {
          if (item.curForms) {
            item.curForms = item.curForms.split(",");
          }
        }
        this.classlist = res.rows;
        for (const item of this.classlist) {
          const data =
            item.teacherDtoVos && item.teacherDtoVos.length > 0
              ? item.teacherDtoVos[0]
              : null;
          item.tImg = data ? data.terImg : "";
          item.terName = data ? data.userName : "";
        }
      });
    },
    // 获取项目专业
    async getKind() {
      await getMYTenantKindZyList().then((res) => {
        if (res.code == 0) {
          this.zhuanList = res.data;
          const oldList = [];
          this.zhuanList.filter((item) => {
            if (item.pKind == 3) {
              item.num = 1;
              item.pName = "普通专升本";
              oldList.push(item);
            }
            if (item.pKind == 1) {
              item.num = 2;
              item.pName = "自学考试";
              oldList.push(item);
            }
            if (item.pKind == 9) {
              item.num = 3;
              item.pName = "普通考研";
              oldList.push(item);
            }
            if (item.pKind == 6) {
              item.num = 4;
              item.pName = "教师资格证";
              oldList.push(item);
            }
            if (item.pKind == 7) {
              item.num = 5;
              item.pName = "计算机等级";
              oldList.push(item);
            }
            if (item.pKind == 5) {
              item.num = 6;
              item.pName = "英语等级";
              oldList.push(item);
            }
          });
          const len = oldList.length;
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < i; j++) {
              if (oldList[j].num > oldList[i].num) {
                const temp = oldList[j];
                oldList[j] = oldList[i];
                oldList[i] = temp;
              }
            }
          }
          this.majorList = oldList;
        } else {
          this.zhuanList = [];
        }
      });
    },
    // 点击图片跳转到知识套餐详情页
    imgxq(index, item) {
      const routeUrl = this.$router.resolve({
        name: "知识套餐课详情",
        query: {
          id: item.id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    /* ============VIP专属班列表============ */
    selectPageListToIndexRecommend() {
      selectNewPageListToIndex(
        6,
        1,
        // this.pageSize,
        // this.pageNum,
        // this.search.school,
        undefined,
        this.search.kind,
        this.search.zyId,
        undefined,
        3 /* productLine 3专属线 */,
        undefined,
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {
        this.RecommendClassList = res.rows;
        this.total = res.total;
      });
    },
    /* 获取试卷包列表 */
    // selectNewPaperListJxu() {
    //   selectNewPaperListJxu
    // }
    /* 点击进入专属班详情 */
    goDetails(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // selectPageListToIndex() {
    //   selectPageListToIndex(
    //     6,
    //     1,
    //     // this.pageSize,
    //     // this.pageNum,
    //     this.search.school,
    //     // undefined,
    //     this.search.kind,
    //     this.search.zyId,
    //     undefined,
    //     3, /* productLine 3专属线 */
    //     this.search.areaId == 1 ? undefined : this.search.areaId
    //   ).then((res) => {
    //     this.exclusiveClassList = res.rows
    //     this.total = res.total
    //   })
    // },
    // 点击立即报名按钮 跳转支付页
    goPage() {
      // if (!this.userInfo) {
      //   Vue.prototype.goLoginView(false);
      //   return;
      // } else {
      this.saveImg("/partnerPage/publicClass");
      // }
    },
    async zhifu(item) {
      if (this.userInfo) {
        const res = await getClassCourseListById(this.userInfo.id, 3);
        res.rows.filter((items) => {
          if (items.coursesId == item.id) {
            this.$message({
              message: "当前已拥有此课程，无法下单",
              type: "error",
            });
            this.unPurchase = false;
          }
        });
        if (this.unPurchase) {
          this.$router.push(`/orders/classOrder?classId=${item.id}`);
        } else {
          this.unPurchase = true;
        }
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.seventhOpen {
  background-color: #f5f5f5;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 1200px;
    margin: 30px auto 22px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  /* 公开课 */
  .classlist {
    width: 1200px;
    height: 380px;
    opacity: 1;
    margin: 0 auto;

    .courseList {
      display: flex;
      margin: 12px auto 0;
      flex-wrap: wrap;
      justify-content: start;

      .openClassTime {
        position: relative;
        display: flex;
        width: 282px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;

          .openClasslistTime {
            width: 100%;
          }
        }

        height: 30px;

        .openClasslistTime {
          width: 282px;
          height: 2px;
          background: #e7e2e2;
          // border: 1px dotted #afafaf;
          opacity: 1;
          position: absolute;
          left: 0;
          right: 0;
          top: 13px;
          // z-index:1;
          //  margin:30px 10px 0 17px;
        }

        .liveTime {
          display: flex;
          justify-content: center;
          background-color: #f5f5f5;
          font-size: 14px;
          width: 49%;
          height: 20px;
          line-height: 20px;
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
          z-index: 1;
          margin: 0 auto;

          span {
            margin-right: 5px;
          }

          img {
            // margin: auto;
            margin: 3px 3px 0;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            vertical-align: middle;
          }
        }
      }

      // margin-top: 12px;
      /* 正在直播 */
      .liveIng {
        .timeItem {
          color: #4a6af0 !important;

          .iconfont {
            color: #4a6af0 !important;
          }
        }

        &:hover {
          transform: translateY(-10px);
        }
      }

      .courstItem {
        position: relative;
        cursor: pointer;
        width: 282px;
        height: 331px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(204, 204, 204, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin: 45px 20px 24px 0px;
        transition: all 0.5s;

        // transition: all 300ms;
        &:last-child {
          margin-right: 0px;
        }

        // &:hover {
        //   z-index: 2;
        //   box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
        //   width: 278px;
        //   transform: translate(-27px, -20px);
        //   // transform: scale(1.14);
        //   .but {
        //     display: none;
        //     width: 105px;
        //     //  padding-left: 21px;
        //     box-shadow: 0 1px 5px 5px rgba(0, 170, 140, 0.1);
        //     transform: translate(-50%-16px, 35px);
        //   }
        //   .butbig {
        //     display: block;
        //     // width: 105px;
        //     //  padding-left: 21px;
        //     transform: translate(-50%-16px, 35px);
        //   }
        //   .contentItem {
        //     z-index: 12;
        //     display: block;
        //     width: 278px;
        //     border-radius: 0 0 8px 8px;
        //     transform: translateY(-135px);
        //   }
        //   .liveTime {
        //     transform: scale(1, 1);
        //   }
        //   .openClassImg {
        //     z-index: 12;
        //     width: 278px;
        //     .duan {
        //       height: 184px;
        //       display: block;
        //     }
        //     .chang {
        //       display: none;
        //     }
        //   }
        // }
        .openClassImg {
          position: relative;
          border-radius: 8px 8px 0px 0px !important;
          width: 282px;
          height: 158px;
          overflow: hidden;

          .chang {
            width: 100%;
            height: 100%;
            display: block;
          }

          .living {
            width: 80px;
            height: 22px;
            position: absolute;
            top: 5px;
            left: 5px;

            img {
              width: 80px;
            }
          }
        }

        .timeLine {
          width: 100%;
          height: 1px;
          padding: 0 10px;
          background-color: #cecece;
          // margin-bottom: 10PX;
        }

        .itemImg {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background: #c4c4c4;
          opacity: 1;
          border-radius: 50%;
          margin-right: 5px;
        }

        .el-divide {
          height: 1px;
          background: #dee1ef;
          margin: 20px 0px;
        }

        .contentItemActive {
          // height: 80px;
          padding-top: 12px;

          .teaImg {
            width: 38px;
            height: 38px;
            margin-right: 12px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .teaName {
            height: 16px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #777777;
          }

          .line {
            margin: 21px auto 0;
            width: 246px;
            height: 0px;
            opacity: 1;
            border-bottom: 1px solid #e9ebf4;
          }

          .contentItemActive_day {
            margin-left: 15px;
            height: 46px;
            line-height: 23px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .contentItemActive_time {
            margin-left: 18px;
            height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }

        .but {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 23px;
          right: 18px;
          bottom: 21px;
          z-index: 1;
          width: 80px;
          height: 32px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #1c81f5;
          border-radius: 28px 28px 28px 28px;
          opacity: 1;
          border: 1px solid #1c81f5;
        }

        .end {
          background: #f7f9fb;
          border-radius: 28px 28px 28px 28px;
          opacity: 1;
          border: 1px solid rgba(0, 0, 0, 0);
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #aaaaaa;
        }

        .live {
          background-color: #1c81f5;
          color: #fff;
          box-shadow: 0 3px 12px 1px rgba(28, 129, 245, 0.34);
        }

        .butbig {
          position: absolute;
          display: none;
          padding-right: 23px;
          right: 0px;
          bottom: 4px;
          z-index: 1;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          width: 120px;
          box-shadow: 0px 2px 10px 1px rgba(0, 170, 140, 0.3);
          // padding-left: 11px;
          height: 38px;
          line-height: 0px;
          color: #fff;
          border-color: #00ab8c;
          background-color: #00ab8c;
        }

        .contentItem {
          display: none;
          height: 178px;
          width: 278px;
          background: #fff;
          padding: 12px 16px 12px;

          .contentItem_tips {
            // margin: 0px auto 0;
            // margin-left: 7px;
            width: 192px;
            height: 83px;
            // padding: 6px 0;
            // background: #f5f5f5;
            // box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
            // border-radius: 8px 8px 8px 8px;
            opacity: 1;

            p {
              width: 100%;
              height: 18px;
              margin-bottom: 9px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #666666;

              span {
                color: #00ab8c;
              }
            }
          }

          .timeItem {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #4a6af0;
            line-height: 12px;
            margin-bottom: 16px;

            .iconfont {
              font-size: 12px;
              color: #4a6af0;
              margin-right: 4px;
            }
          }

          .titleItem {
            height: 24px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-bottom: 2px;
            // margin-top: 12px;
          }

          .teacherItem {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .terImg {
              width: 14px;
              height: 14px;
              margin-right: 4px;
              flex-shrink: 0;
            }

            .itemBox {
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              // color: #f5f5f5;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
