<template>
  <div class="bannerImgBox">
    <div ref="gradient" class="gradient" />
    <div class="MaskLayer" />
    <div class="bannerImg">
      <div class="content">
        <!--项目专业-->
        <div class="swipersLefts" @mouseleave="swipersLeftsa()">
          <!-- 自学考试 -->
          <div class="list">
            <div v-for="(item, index) in collageList" :key="index" class="item"
              :class="kindIndex && kindIndex.pKind == item.pKind ? 'on' : ''" @mouseenter="swipersLeftsa(item)">
              <div class="flex-c" style="width: 100%" @click.stop="projectDetail(item)">
                <div class="item-title pName elp">{{ item.pName }}</div>
                <!-- <i
                  class="el-icon-caret-right"
                  style="margin-right: 4%; color: #888"
                /> -->
                <img style="width: 7px" src="@/assets/img/partner/leftArrow.png" alt="">
              </div>
              <!-- 内容0 -->
              <!-- <div class="item-content">
                <div>
                  <template v-if="item.pStage">
                    <span
                      v-for="(itemsname, index) in item.pStage"
                      :key="index"
                      class="pname"
                      @click="projectDetail(item)"
                      ><span v-show="index != 0">/</span
                      >{{ itemsname.pnames }}</span
                    >
                  </template>
                  <template v-else>
                    <span
                      v-if="
                        item.pKind == 7 || item.pKind == 5 || item.pKind == 3
                      "
                      class="pname"
                      @click="projectDetail(item)"
                      >{{ item.pnames }}</span
                    >
                    <span
                      v-for="(itemsname, index) in item.pnames"
                      v-else
                      :key="index"
                      class="pname"
                      @click="projectDetail(item)"
                      ><span v-show="index != 0">/</span>{{ itemsname }}</span
                    >
                  </template>
                </div>
              </div> -->
            </div>
          </div>
          <div v-if="kindIndex" class="wrapBottom" />
          <div v-if="kindIndex" class="wrap" @mouseenter="swipersLeftsa(kindIndex)" @mouseleave="swipersLeftsa()">
            <div v-if="kindIndex.pStage">
              <template v-for="(stageName, starIndex) in kindIndex.stageZyList">
                <!-- 高起专不展示 -->
                <div v-if="showProject(kindIndex.pKind, stageName.stage)" :key="starIndex" class="title">
                  <span @click="projectDetail(kindIndex)">
                    {{
                      stageName.name == '专升本'
                      ? '自考专升本'
                      : stageName.name
                    }}<i class="el-icon-arrow-right" style="margin-left: 5px" />
                  </span>
                  <template>
                    <div class="wraps">
                      <div v-for="(itemsss, indexsss) in stageName.zyList" v-show="stageName.name == itemsss.zStage"
                        :key="indexsss" class="wrapss ellipsis" @click.stop="
                          zhuanYeXqs(itemsss.zKind, itemsss.zId, itemsss.pKind)
                          ">
                        {{ itemsss.zName }}
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div v-else>
              <div class="title">
                <div @click="projectDetail(kindIndex)">
                  {{ kindIndex.pName
                  }}<i class="el-icon-arrow-right" style="margin-left: 5px" />
                </div>
                <template>
                  <div v-if="courseClass.length > 0" class="wraps">
                    <div v-for="(zy, zyIndex) in kindIndex.zyList" :key="zyIndex" class="wrapss" @click.stop="
                      zhuanYeXqs(zy.zKind, zy.zId, kindIndex.pKind)
                      ">
                      {{ zy.zName ? zy.zName : '' }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- <div class="colleges_title">
              <div class="text">
                <div>热门推荐</div>
              </div>
            </div>
            <div class="contentWrapLeft">
              <div
                v-for="(item, index) in courseClass"
                :key="index"
                class="contentItem"
                @click="goDetails(item)"
              >
                <div class="imgItem">
                  <el-image :src="item.img" class="imgClass" fit="cover" />
                </div>
                <div class="item_content">
                  <div class="titleItem twoEllipsis">{{ item.tiitle }}</div>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <!-- 轮播 -->
        <el-carousel ref="carousel" class="swImg" indicator-position="none" @change="changeItem">
          <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer" :autoplay="false">
            <el-image ref="bannerimg" :src="item.img" @click="nav(item)" />
          </el-carousel-item>
        </el-carousel>
        <div class="menu-news" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import {
  getSurfaceList,
  getMYTenantKindZyList,
  selectKindTenantIdProducts,
  selectNewPageListToIndex
} from '@/api/home'
import { queryAllArticle } from '@/api/home'
import { getDomain } from '@/api/cookies'
import { Know } from '@/api/know'
const know = new Know()
export default {
  data() {
    return {
      imgList: [],
      newImgList: [],
      kindIndex: null,
      collageList: [],
      newsList: [],
      domain: null,
      active: null,
      search: {},
      index: null,
      gradient: null,
      stageList: [],
      pStageList: [],
      courseClass: [],
      RecommendClassList: []
    }
  },
  created() {
    this.domain = getDomain()
    /* 获取轮播图 */
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg
      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
    /* 新闻消息 */
    queryAllArticle('', 4, 1).then((res) => {
      this.newsList = res.rows
    })
  },
  methods: {
    showProject(pKind, stage) {
      if (pKind === 1) {
        if (stage === 0) return false
        return true
      }
      return true
    },
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole, itmse) {
      const routeUrl = this.$router.resolve({
        path: `/partnerPage/typeclass?kind=${kind || ''}&zId=${zId || ''
          }&schoole=${schoole || ''}`
      })
      window.open(routeUrl.href, '_blank')
    },

    // 将tab的下标指定为走马灯的下标
    clickBtn(index) {
      this.$refs.carousel.setActiveItem(index)
      this.active = index // tab切换的下标
    },
    // 监听轮播的改变，实现背景色渐变
    changeItem(e) {
      this.$refs.gradient.style.background = `url(${this.imgList[e].img})`
      this.active = e
    },
    projectDetail(item) {
      this.$router.push({
        path: `/projectChannelPage`,
        query: {
          id: item.pKind
          // schoolId: 1
        }
      })
    },
    selectPageListToIndexRecommend(item) {
      selectNewPageListToIndex(
        4,
        1,
        undefined,
        item.pKind,
        undefined,
        undefined,
        3, // 知识套餐精品班型
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {

        res.pKind = item.pKind
        this.RecommendClassList.push(res)
        this.$forceUpdate()
      })
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 项目专业
    loadgetTenantidNavigation() {
      selectKindTenantIdProducts().then(res => {
        this.collageList = res.data
        this.collageList.filter(async (item) => {
          //  
          await this.selectPageListToIndexRecommend(item)
        })
      })
      // getMYTenantKindZyList(3).then((res) => {
      //   if (res.code == 0) {
      //     const oldCollageList = res.data
      //     const newCollageList = []
      //     oldCollageList.filter((item) => {
      //       if (item.pStage) {
      //         item.pStage = JSON.parse(item.pStage)
      //       }
      //       if (item.pKind == 3) {
      //         item.num = 1
      //         item.pName = '统招专升本'
      //         item.pnames = '统招专升本'
      //         newCollageList.push(item)
      //       } else if (item.pKind == 1) {
      //         item.num = 2
      //         item.pName = '自考专升本'
      //         item.pnames = '自考专升本'
      //         item.pStage = [
      //           {
      //             pnames: '自考专升本'
      //           }
      //         ]
      //         // item.pStage.filter((items) => {
      //         //   if (items.name == '高起专') items.pnames = '专科'
      //         //   if (items.name == '专升本') items.pnames = '本科'
      //         // })
      //         newCollageList.push(item)
      //       } else if (item.pKind == 9) {
      //         item.num = 3
      //         item.pName = '普通考研'
      //         item.pnames = ['专硕', '学硕']
      //         item.pStage.filter((items) => {
      //           if (items.name == '专业硕士') items.pnames = '专硕'
      //           if (items.name == '学术硕士') items.pnames = '学硕'
      //         })
      //         newCollageList.push(item)
      //       } else if (item.pKind == 6) {
      //         item.num = 4
      //         item.pnames = []
      //         item.pName = '教师资格证'
      //         item.pnames = ['幼儿园', '小学', '中学']
      //         item.zyList.filter((items) => {
      //           if (items.zName == '小学教师资格') {
      //             items.pnames = '小学'
      //             items.num = 2
      //           }
      //           if (items.zName == '幼儿园教师资格') {
      //             items.pnames = '幼儿园'
      //             items.num = 1
      //           }
      //           if (items.zName == '中学教师资格证') {
      //             items.pnames = '中学'
      //             items.num = 3
      //           }
      //         })
      //         const len = item.zyList.length
      //         // if (len < 2) return oldCollageList;
      //         for (let i = 0; i < len; i++) {
      //           for (let j = 0; j < i; j++) {
      //             if (item.zyList[j].num > item.zyList[i].num) {
      //               const temp = item.zyList[j]
      //               item.zyList[j] = item.zyList[i]
      //               item.zyList[i] = temp
      //             }
      //           }
      //         }
      //         newCollageList.push(item)
      //       } else if (item.pKind == 5) {
      //         item.num = 5
      //         item.pnames = '英语四级'
      //         item.pName = '英语等级'
      //         newCollageList.push(item)
      //       } else if (item.pKind == 7) {
      //         item.num = 6
      //         item.pName = '计算机等级'
      //         console.log(item)
      //         item.pnames = '三级证书'
      //         newCollageList.push(item)
      //       }
      //     })
      //     const len = newCollageList.length
      //     for (let i = 0; i < len; i++) {
      //       for (let j = 0; j < i; j++) {
      //         if (newCollageList[j].num > newCollageList[i].num) {
      //           const temp = newCollageList[j]
      //           newCollageList[j] = newCollageList[i]
      //           newCollageList[i] = temp
      //         }
      //       }
      //     }
      //     this.collageList = newCollageList
      //     this.collageList.filter(async (item) => {
      //       console.log(item)
      //       await this.selectPageListToIndexRecommend(item)
      //     })
      //   } else {
      //     this.collageList = []
      //   }
      // })
    },
    // 自学考试滑入
    swipersLeftsa(item) {
      this.search.kind = item?.pKind || null
      this.kindIndex = item || null
      this.courseClass = []
      this.RecommendClassList.filter((items) => {
        // console.log(items)
        if (items.pKind == item?.pKind) {
          // console.log(items)
          console.log(this.courseClass)
          this.courseClass = items.rows || []
        }
      })
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    },
    toInformationDetails(item) {
      const routeUrl = this.$router.resolve({
        name: '新闻资讯详情',
        params: { id: item.id }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImgBox {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .gradient {
    position: absolute;
    top: -20px;
    width: 100%;
    backdrop-filter: blur(200px);
    height: 310px;
    opacity: 0.3;
    // background-color: rgba(116, 116, 116, 0.4);
    margin: auto;
  }

  .MaskLayer {
    position: absolute;
    top: -20px;
    height: 400px;
    z-index: 1;
    background-image: linear-gradient(to top,
        rgba(246, 246, 246, 1),
        rgba(245, 245, 245, 0.6),
        rgba(245, 245, 245, 0.1));
    backdrop-filter: blur(110px);
    width: 100%;
  }

  .bannerImg {
    position: absolute;
    z-index: 2;
    align-self: center;
    height: 310px;
    position: relative;
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    // background-color: #333333;
    justify-content: space-between;
  }
}

/* ===========项目专业============= */
// 缩宽
.wrapBottom {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 217px;
  // backdrop-filter: blur(20px);
  width: 658px;
  z-index: 99;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;
  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  border-radius: 0px 8px 8px 0px;
}

.wrap {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 172px;
  width: 640px;
  height: 310px;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  z-index: 99999;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;

  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  .colleges_title {
    .text {
      width: 64px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    margin-top: 20px;
    margin-bottom: 12px;
  }

  .contentWrapLeft {
    width: 100%;
    display: flex;
    justify-content: start;

    .contentItem {
      width: 25%;

      .imgClass {
        width: 130px;
        height: 74px;
        border-radius: 4px;
      }

      &:hover {
        color: #3d6cc8;
      }

      .item_content {
        width: 125px;
        margin-top: 6px;
        height: 35px;
        font-size: 12px;
        line-height: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      }
    }
  }

  .title {
    font-size: 15px;
    font-family: Microsoft YaHei-Bold;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    padding-top: 17px;
  }

  .wraps {
    width: 100%;
    height: auto;
    display: flex;
    font-size: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .wrapss {
      // width: calc(100% / 5);
      width: 120px;
      margin-top: 18px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      text-align: left;

      &:hover {
        color: #3d6cc8;
        // border-bottom: 1px solid #ff5d51;
      }
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

/* ========================== */
.content {
  width: 100%;
  border-radius: 10px !important;

  .swipersLefts {
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 16px;
    width: 172px;
    height: 310px;

    border-radius: 10px 0px 0px 0px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3px 6px 1px rgba(204, 204, 204, 0.16);
    // border-radius: 10px 0px 0px 0px;
    opacity: 1;

    .list {
      width: 100% !important;
      height: 310px;

      // background-color: rgba(116, 116, 116, 0.4);
      // backdrop-filter: blur(20px);
      // box-shadow: 0 0 5px 0px rgba(39, 39, 39, 0.4);
      // height: 270px;
      // display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      // background: black;
      overflow-y: scroll;
      border-radius: 10px 0px 0 10px;

      // 自学考试
      .item {
        width: 100%;
        height: 51.6px;
        padding: 0 15px 0 20px;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        opacity: 1;
        display: flex;
        justify-content: space-between;

        &:hover {
          .item-title {
            color: #3d6cc8 !important;
          }

        }

        // margin: 4px 0;
        .item-img {
          flex-shrink: 0;
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }

        .item-title {
          width: 100%;
          height: 16px;
          font-size: 15px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 16px;

          &:hover {
            color: #3d6cc8;
          }
        }

        // 内容
        .item-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          scrollbar-width: none;
          font-size: 12px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: #666666;
          line-height: 16px;

          .pname {
            &:hover {
              color: #3d6cc8;
            }
          }

          .item-content-name {
            // min-width: calc(40% - 8px);
            max-width: calc(50% - 8px);
            margin-right: 5px;
            flex-shrink: 0;
            white-space: nowrap; //强制不换行
            opacity: 0.7;

            &:last-child {
              margin-right: 0px !important;
            }
          }
        }


      }

      // .on {
      //   border-left: 4px solid #ff5e51;
      //   // background: rgba(255, 255, 255, 0.2);
      // }
    }
  }

  // tab按钮
  .distrbute_btns {
    position: absolute;
    display: flex;
    z-index: 1000;
    height: 168px;
    width: 172px;
    background: black;
    top: 46px;
    border-radius: 8px;
    padding: 18px 8px;
    background-color: rgba(78, 78, 78, 0.4);
    right: 39px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .tabBanner {
      font-size: 14px;
      width: 140px !important;
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 100%;
      color: #cccccc;
      background: none;
      border: none;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }

    .active {
      color: #ffffff;
      opacity: 1 !important;
    }
  }

  /* 轮播图 */
  /deep/.swImg {
    width: 100%;
    height: 310px;
    border-radius: 10px 0 0 0;
    opacity: 1;

    img {
      // max-width: 100%;
      height: 310px;
      width: 1200px;
      margin: auto;
    }

    .el-carousel__item {
      width: 100%;
      height: 100%;
    }

    .el-carousel__container {
      height: 100%;
    }

    .el-carousel__arrow {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.2);
    }

    i {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  /deep/.el-carousel__arrow--left {
    position: absolute;
    left: 226px;
  }

  /deep/.el-carousel__arrow--right {
    position: absolute;
    right: 26px;
  }

  .menu-news {
    display: flex;
    margin-top: 17px;
    /* 新闻咨询 */

    .news {
      width: 811px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 24px;
      background: url('~@/assets/img/homeSeventh/newBg.png') no-repeat;
      background-size: 100% 100%;

      .news-content {
        display: flex;
        margin-top: 18px;

        .news-img {
          width: 248px;
          height: 143px;
          margin-right: 22px;
          cursor: pointer;

          &:nth-child(1) {
            margin-right: 24px;
          }
        }

        .news-text {
          padding: 14px 0px 0px;

          .news-item {
            margin-bottom: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d636d;
            line-height: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0px;
            }

            .icon {
              width: 6px;
              height: 6px;
              background: #dddddd;
              opacity: 1;
              border-radius: 50%;
              flex-shrink: 0;
              margin-right: 8px;
            }

            .title {
              width: 207px;
            }
          }
        }
      }
    }

    /* 金刚区 */
    .menuList {
      width: 257px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;

      margin-left: 16px;

      .menu-box {
        border-bottom: 1px solid #edeff4;
        width: 100%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        .menu-item {
          width: 50%;
          border-bottom: 1px solid #fff;
          height: 100%;
          padding-top: 28px;

          .menu-img {
            width: 32px;
            height: 32px;
            margin: 0px auto;
          }

          .text {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            text-align: center;
            margin-top: 12px;
          }

          &:nth-child(1) {
            border-right: 1px solid #edeff4;
          }
        }
      }
    }
  }
}
</style>