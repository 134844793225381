<template>
  <!-- 精品课程-->
  <div v-if="list && list.length > 0" class="p-t-20 open">
    <!--标题 最近直播 -->
    <div class="home-three-title">
      <div class="text">
        <img class="item-img" src="@/assets/img/homeThree/hot.png">
        <slot>精品课程</slot>
      </div>
      <span class="more" @click="zhibokes">查看更多&nbsp;></span>
    </div>
    <!-- 最近直播  内容 -->
    <div class="live-all">
      <div v-if="list && list.length > 0" class="live">
        <img class="bag" src="@/assets/img/homeThree/bagOpen.png">
        <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
          <!-- 活动详情 -->
          <div class="item-img"><img :src="item.img"></div>
          <div class="detail">
            <div class="detail-title twoEllipsis">{{ item.title }}</div>
            <div class="item-tips elp">"{{ item.pName }}<span> · </span> {{ item.kindName }}"</div>
            <!-- 价格 -->
            <div class="flex-between-c price-box">
              <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
              <div class="tips">人气值 {{ item.clicks? item.clicks:0 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list2 && list2.length > 0" class="live">
        <img class="bag" src="@/assets/img/homeThree/bagOpen.png">

        <div v-for="(item, index) in list2" :key="index" class="item" @click="goPage(item)">
          <!-- 活动详情 -->
          <div class="item-img"><img :src="item.img"></div>
          <div class="detail">
            <div class="detail-title twoEllipsis">{{ item.title }}</div>
            <div class="item-tips elp">"{{ item.pName }}<span> · </span> {{ item.kindName }}"</div>

            <!-- 价格 -->
            <div class="flex-between-c price-box">
              <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
              <div class="tips">人气值 {{ item.clicks? item.clicks:0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: {},
  data() {
    return {
      list: [], // 公开课
      list2: []
    }
  },
  created() {
    know.getCourseList(6, 1).then((res) => {
      this.list = res.rows.filter((item, index) => {
        return index < 3
      })
      this.list2 = res.rows.filter((item, index) => {
        return index > 2
      })
    })
  },
  methods: {
    dealType(type) {
      let temp = null
      switch (type) {
        case 1:
          temp = '直播'
          break
        case 2:
          temp = '录播'
          break
        case 3:
          temp = '音频'
          break
        case 4:
          temp = '图文'
          break
        case 5:
          temp = '面授'
          break
        default:
          ''
      }
      return temp
    },
    // 查看更多直播课按钮
    zhibokes() {
      const routeUrl = this.$router.resolve({
        path: '/jingpin/courer'
      })
      window.open(routeUrl.href, '_blank')
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.open {
  margin-bottom: 60px;
}
.home-three-title {
  width: 1220px !important;
  padding-bottom: 32px;
  padding-top: 0px;
}
.live-all {
  width: 1220px;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  // 最近直播  内容
  .live {
    width: 590px;
    height: 514px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    padding: 32px;
    .bag {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 60px;
      left: 0px;
    }
    .item {
      margin-bottom: 24px;
      width: 100%;
      height: 126px;
      background: #ffffff;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      margin-bottom: 36px;
      .item-img {
        width: 224px;
        height: 126px;
        border-radius: 8px;
        overflow: hidden;
        opacity: 1;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
      .detail {
        width: 100%;
        margin-left: 16px;
        .detail-title {
          height: 48px;
          font-size: 16px;
          line-height: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .item-tips {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ff7b1c;
          line-height: 12px;
          margin-top: 12px;
        }
        .tips {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
        }
        .price-box {
          margin-top: 24px;
          width: 100%;
          align-items: flex-end;
          .price {
            font-family: Microsoft YaHei-Regular;
            color: #ff5d51;
            font-size: 24px;
            line-height: 24px;
            font-weight: Bold;
            .price-icon {
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
