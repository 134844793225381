<template>
  <div class="seventhOpen">
    <!-- 公开课 -->
    <div class="til">
      <div>直播课</div>
      <div @click="saveImg('/seventhPage/zhiboClass')">查看更多 <i class="iconfont el-icon-arrow-right"
          style="font-size:12px" /></div>
    </div>
    <div class="classlist box-page">
      <div class="courseList">
        <div v-if="loading" v-loading.lock="loading" style="width:100%;height:358px;background:#fff" />
        <div v-for="item in openClasslist" v-else :key="item.id" class="courstItem" content
          :class="item.state == 1 ? 'liveIng' : ''" @click="godetail(item)">
          <div class="openClasslistTime" />
          <div class="liveTime">
            <img src="../../style/image/lives.png" alt="">
            <span>{{ item.endTimeNew }}</span>
          </div>
          <div class="openItem">
            <div class="openClassImg">
              <el-image :src="item.img" fit="cover" />
            </div>
            <div v-if="item.isLive == 1" class="liveState">
              <el-image class="liveState_img" :src="require('@/assets/img/homeSeventh/liveState.png')" />
              <span>直播中</span>
            </div>
            <div class="contentItem">
              <div class="titleItem twoEllipsis">{{ item.liveshowName }}</div>
              <div class="timeItem">
                <!-- <i class="iconfont icon-daishouhuo" /> -->
                <span>直播时间：{{ item.startTime }}</span>
              </div>
              <div class="timeLine" />
              <!-- <el-divider /> -->
              <div class="teacherItem">
                <div class="flex-c">
                  <div class="itemImg"><el-image :src="item.tImg" fit="cover" /></div>
                  <div class="itemBox">{{ item.terName }}</div>
                  </el-image>
                </div>
                <!-- <el-button class="but" :class="item.state== 1 ?liveing :'' " round>{{ item.state }}</el-button> -->
                <el-button v-if="item.isLive == 0" class="but liveing" round>未开始</el-button>
                <el-button v-if="item.isLive == 1" class="but liveing" round>进入直播</el-button>
                <el-button v-if="item.isLive == 2" class="but liveing" round>回放生成中</el-button>
                <el-button v-if="item.isLive == 3" class="but liveend" round>已结束</el-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="400px" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ""
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import {
  Know,
  courseCheckPlay,
  getNewLiveshowplaybackByTypes
} from '@/api/know'
const know = new Know()
import { getMYTenantKindZyList, getLbClassCourseById } from '@/api/home'
// import { selectPageList } from '@/api/task/answerClient'
import { getInfo } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import {
  selectKindAllCollage
  // getSurfaceList,
  // selectCoursePages
} from '@/api/home'
export default {
  components: {},
  data() {
    return {
      openClasslist: [], // 公开课
      collageList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null
      },
      live: null,
      loadingBtn: false,
      unPurchase: true,
      showMore: '在线题库',
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: 'first',
      ifHave: false,
      liveId: null,
      loading: false,
      liveshowName: '',
      lookDeatil: null,
      showLookDetail: false,
      goBack: false
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.searchClick()
    // await this.getshoolList()
    await this.getKind()
    // this.getPaperMajor()
  },
  methods: {
    // 项目专业
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code === 0) {
          this.collageList = res.data
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = []
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
      if (this.goBack) {
        window.close()
      }
    },
    // 跳转试卷包和资料详情页
    godateDetail(row) {
      this.showMore = row.label
      // this.saveImg('/seventhPage/quesTionList')
    },
    handleClick() { },
    school(val) {
      if (val) {
        this.search.kindList = val
        this.search.kindName = val?.pName
        this.search.kind = val?.pKind
      } else {
        this.search.kind = null
      }

      this.searchClick()
    },

    searchClick() {
      this.getOpenClass()
    },
    /* =======直播课================= */
    getOpenClass() {
      this.loading = true
      const param = {
        pageSize: 4,
        pageNum: 1,
        kind: this.search.kind ? this.search.kind : null,
        zyId: this.search.zyId ? this.search.zyId : null
      }
      know.getNewLiveshowplaybackByTypes(param).then((res) => {
        this.openClasslist = res.rows.filter((item, index) => {
          const month = new Date(item.startTime).getMonth() + 1
          const date = new Date(item.startTime).getDate()
          const Hour = new Date(item.startTime).getHours()
          const Minutes = new Date(item.startTime).getMinutes()
          const nowMonth = new Date().getMonth() + 1
          const nowDate = new Date().getDate()
          const nowHour = new Date().getHours()
          const nowMinutes = new Date().getMinutes()
          if (
            nowDate > date &&
            nowMonth > month &&
            nowHour > Hour &&
            nowMinutes > Minutes &&
            item.isLive != 1
          ) {
            item.endTimeNew = `${month <= 9 ? '0' + month : month}月${date <= 9 ? '0' + date : date
              }日` // ${hour <= 9 ? '0' + hour : hour}:${minute <= 9 ? '0' + minute : minute}
            item.state = '已结束'
          } else if (item.isLive == 1) {
            item.endTimeNew = `${month <= 9 ? '0' + month : month}月${date <= 9 ? '0' + date : date
              }日` // ${hour <= 9 ? '0' + hour : hour}:${minute <= 9 ? '0' + minute : minute}

            item.state = '进入直播'
          } else {
            item.endTimeNew = `${month <= 9 ? '0' + month : month}月${date <= 9 ? '0' + date : date
              }日` // ${hour <= 9 ? '0' + hour : hour}:${minute <= 9 ? '0' + minute : minute}
            item.state = '未开始'
          }
          this.loading = false
          return index < 4
        })
      })
    },
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true)
        return
      }
      this.liveId = item.liveId
      this.liveshowName = item.liveshowName
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase
        this.live = res.data.details
      })
      if (this.ifHave) {
        return
      }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      // this.$router.push({
      //   path: '/livebofang',
      //   query: {
      //     id: item.id,
      //     startTime: item.startTime,
      //   },
      // })
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang`,
            query: {
              id: item.id,
              startTime: item.startTime,
              chapterName: item.title
            }
          })
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href
          } else {
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
          this.goBack = true
        }
      })
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null
      }
      know.addPublicityClassStudentRecord(data).then((res) => { })
    },

    // 获取项目专业
    async getKind() {
      await getMYTenantKindZyList().then((res) => {
        if (res.code == 0) {
          this.zhuanList = res.data
          const oldList = []
          this.zhuanList.filter((item) => {
            if (item.pKind == 3) {
              item.num = 1
              item.pName = '普通专升本'
              oldList.push(item)
            }
            if (item.pKind == 1) {
              item.num = 2
              item.pName = '自学考试'
              oldList.push(item)
            }
            if (item.pKind == 9) {
              item.num = 3
              item.pName = '普通考研'
              oldList.push(item)
            }
            if (item.pKind == 6) {
              item.num = 4
              item.pName = '教师资格证'
              oldList.push(item)
            }
            if (item.pKind == 7) {
              item.num = 5
              item.pName = '计算机等级'
              oldList.push(item)
            }
            if (item.pKind == 5) {
              item.num = 6
              item.pName = '英语等级'
              oldList.push(item)
            }
          })
          const len = oldList.length
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < i; j++) {
              if (oldList[j].num > oldList[i].num) {
                const temp = oldList[j]
                oldList[j] = oldList[i]
                oldList[i] = temp
              }
            }
          }
          this.majorList = oldList
        } else {
          this.zhuanList = []
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.seventhOpen {
  margin-top: 30px;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  .lookBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .ifLook {
      width: 500px;
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #ccc;

      .contents {
        width: 380px;
        margin: 20px auto;

        &>span {
          color: red;
        }
      }

      .button {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 1200px;
    height: 24px;
    margin: 0 auto;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  /* 公开课 */
  .classlist {
    margin: 20px auto 30px;
    width: 1200px;
    height: 370px;
    opacity: 1;
    margin: 0 auto;

    .courseList {
      display: flex;
      margin: 28px auto 30px;
      flex-wrap: wrap;
      justify-content: space-between;

      // margin-top: 12px;
      /* 正在直播 */
      .liveIng {
        .timeItem {
          color: #666666 !important;

          .iconfont {
            color: #666666 !important;
          }
        }

        /* .but {
        background: #4a6af0 !important;
        color: #ffffff !important;
      } */
      }

      .courstItem {
        position: relative;
        cursor: pointer;
        width: 282px;
        height: 312px;
        background: #fff;
        border-radius: 8px 8px 8px 8px !important;
        opacity: 1;
        // overflow: hidden;
        margin: 22px 22px 24px 0px;

        &:last-child {
          margin-right: 0px;

          .openClasslistTime {
            width: 100%;
          }
        }

        // display: flex;
        // padding: 18px 10px;
        .openClassImg {
          border-radius: 8px 8px 0px 0px !important;
          width: 282px;
          height: 188px;
          overflow: hidden;

          /deep/.el-image {
            height: 188px;
            transition: all 0.5s;
          }
        }

        .liveState {
          display: flex;
          position: absolute;
          top: 12px;
          left: 12px;
          width: 80px;
          height: 22px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          font-size: 12px;
          line-height: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;

          .liveState_img {
            margin: -6px 3px 0 -8px;
            width: 39px;
            height: 40px;
          }
        }

        .timeLine {
          width: 100%;
          height: 1px;
          padding: 0 10px;
          background-color: rgb(245, 245, 245);
          // margin-bottom: 10PX;
        }

        .openClasslistTime {
          width: 400px;
          height: 1px;
          background: #e7e2e2;
          // border: 1px dotted #afafaf;
          opacity: 1;
          position: absolute;
          left: 0;
          right: 0;
          top: -9%;
          z-index: 1;
          //  margin:30px 10px 0 17px;
        }

        .liveTime {
          display: flex;
          justify-content: center;
          background-color: #f5f5f5;
          font-size: 14px;
          width: 40%;
          height: 20px;
          z-index: 1;
          line-height: 20px;
          position: absolute;
          left: 0;
          right: 0;
          top: -36px;
          // z-index:1;
          margin: 0 auto;

          span {
            margin-right: 5px;
          }

          img {
            // margin: auto;
            margin: 3px 3px 0;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            vertical-align: middle;
          }
        }

        .itemImg {
          flex-shrink: 0;
          width: 38px;
          height: 38px;
          background: #c4c4c4;
          overflow: hidden;
          opacity: 1;
          border-radius: 50%;
          margin-right: 5px;
        }

        .el-divide {
          height: 1px;
          background: #dee1ef;
          margin: 22px 0px;
        }

        .openItem {
          transition: all 500ms;
          overflow: hidden;

          &:hover {
            box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
            transform: translateY(-6px);

            .openClassImg {
              /deep/ .el-image {
                transform: scale(1.1);
              }
            }

            .contentItem {
              border-radius: 0 0 8px 8px;

              .teacherItem {
                /deep/ .but {
                  color: #fff;
                  box-shadow: 0px 2px 10px 1px rgba(0, 170, 140, 0.3);
                  border-color: #00ab8c;
                  background-color: #00ab8c;
                }
              }
            }
          }

          .contentItem {
            border-radius: 0 0 8px 8px;
            height: 165px;
            width: 100%;
            background: #fff;
            padding: 12px 16px;

            .timeItem {
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 12px;
              margin-bottom: 16px;

              .iconfont {
                font-size: 12px;
                color: #666666;
                margin-right: 4px;
              }
            }

            .titleItem {
              height: 48px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              // margin-top: 12px;
              margin-bottom: 11px;
            }

            .teacherItem {
              margin-top: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .terImg {
                width: 14px;
                height: 14px;
                margin-right: 4px;
                flex-shrink: 0;
              }

              .itemBox {
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                // color: #f5f5f5;
                line-height: 12px;
              }

              .but {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                width: 80px;
                text-align: center;
                height: 32px;
                line-height: 0px;
                color: #00ab8c;
                border-color: #00ab8c;
                box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
                background-color: #fff;

                .liveing {
                  color: #fff;
                  border-color: #00ab8c;
                  background-color: #00ab8c;
                }


              }


            }
          }
        }
      }
    }
  }
}

.liveend {
  background: #F7F9FB !important;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  color: #AAAAAA !important;
  box-shadow: none !important;
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
