import request from "@/common/utils/request.js";

// 获取所有学院列表
function selectKindAllCollage() {
  return request({
    url: "/ucenter/api/allcur/selectKindAllCollage",
    method: "GET",
  });
}

// 获取所有学院列表下级专业信息1
function selectKindTypeAllCollage(as) {
  return request({
    url: "/ucenter/api/allcur/selectKindTypeAllCollage",
    method: "GET",
    params: {
      // 学院Id
      collageId: as,
    },
  });
}

// 获取所有学院列表下级专业信息2
function selectByKindOrStage(as, bs) {
  return request({
    url: "/ucenter/api/allcur/selectByKindOrStage",
    method: "GET",
    params: {
      // 学院Id
      kind: as,
      stage: bs,
    },
  });
}

// 获取所有学院列表下级专业信息3
function getZykByZyid(as) {
  return request({
    url: "/ucenter/api/allcur/getZykByZyid",
    method: "GET",
    params: {
      // 专业id
      zyid: as,
    },
  });
}
 
// 展开筛选知识套餐信息
function selectCoursePages(zyId, classTypes, type, pageSize, pageNum, kind, productLine) {
  return request({
    url: "/ucenter/api/home/getClassPagesByZy",
    method: "GET",
    params: {
      // 专业Id
      zyId: zyId,
      // 知识套餐类型 4 精品班 2 普通班 1 协议班  5 特价班
      classTypes: classTypes,
      //   类型Id  1知识套餐
      type: type,
      //  分页条件
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      productLine: productLine /* productLine: 1 普通线 2 精品线  3 专属线 4 普通线加精品线 */
    },
  });
}

export {
  selectKindAllCollage,
  selectByKindOrStage,
  selectKindTypeAllCollage,
  getZykByZyid,
  selectCoursePages,
};