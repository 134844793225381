<template>
  <div class="two-box">
    <div v-if="teacherList && teacherList.length > 0" class="teacher-view">
      <!--标题 师资力量 -->
      <div class="til">
        <div class="title">
          精品师资
          <img src="@/assets/img/partner/corner.png" alt="" />
        </div>
        <div style="color: #90939A;" @click="saveImg('/facultyPartner')">
          查看更多
          <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
        </div>
      </div>
      <div class="tea">
        <div class="teaDetail">
          <div class="teaDetail_imgBox">
            <img :src="teamsg.terImg" alt="" />
          </div>
          <div class="titleBox">
            <div class="nameBox">
              <div class="name">{{ teamsg.isRecommend == 1 ? teamsg.userName : teamsg.nickname }}</div>
              <div class="type">{{ teamsg.jobTitle }}</div>
            </div>
            <div class="content">
              <div class="dis_flex_start">
                授课课程
                <div v-for="(item, index) in kcList" :key="index" v-show="index < 2" style="margin-right: 10px">
                  《{{ item }}》
                </div>
              </div>
              <div style="margin-top: 20px">专业资历</div>
              <div class="dis_flex_start">
                <div v-for="(item, index) in kindName" :key="index" style="margin-right: 15px" v-show="index < 2">
                  {{ item }}
                </div>
              </div>
              <div style="margin-top: 20px">教学特色</div>
              <div class="text elp" v-html="ImgSizeLimit(teamsg.profile)"></div>
            </div>
          </div>
        </div>
        <div class="teaBox">
          <div class="teaBox_item" v-for="(item, index) in teacherList" @mouseenter="goDet(item, index)" :key="index">
            <div class="itemimg">
              <img :src="item.terImg" alt="" />
            </div>
            <div class="name">{{ item.isRecommend == 1 ? item.userName : item.nickname }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="terbox">
        <div class="gradient" />
        <el-image class="teapics" :src="require('@/assets/img/homeSeventh/teacherBackgorund.png')" fit="cover" />
        <div class="terf">
          <div />
          <el-image class="xiangkuang" :src="require('@/assets/img/homeSeventh/xiangkuang.png')" />
          <transition name="teacher">
            <el-image v-if="teachIndex" class="terImg" :src="teamsg.terImg" fit="cover" />
          </transition>
        </div>
        <div class="reamsg">
          <div>{{ teamsg.nickname }}</div>
          <div>{{ teamsg.kindName }}<span style="margin-left:15px">{{ teamsg.kinds }}</span> </div>
          <div />
          <div v-html="teamsg.profile" />
          <div class="look" @click="goDetails()">查看详情 <i style="font-size:12px" class="iconfont el-icon-arrow-right" /></div>
        </div>
        <div class="teapic">
          <div
            v-for="(item, index) in teacherList"
            :key="index"
            :class="item.isClick ? 'bor' : ''"
            @mouseenter="goDet(item)"
          >
            <img :src="item.terImg" alt="">
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { TeacherClient } from '@/api/teacherClient'
const teacherClient = new TeacherClient()
export default {
  components: {},
  data() {
    return {
      total: '',
      teacherList: [],
      kindName: [],
      kcList: [],
      pageNum: 1,
      teamsg: {},
      uid: '',
      teachIndex: true
    }
  },
  async created() {
    // 教师
    await teacherClient
      .teacherPageList(8, 1, undefined, 1)
      .then(async (res) => {
        if (res.rows) {
          for (const items of res.rows) {
            items.isClick = false
          }
          const params = {
            lectureId: res.rows[0].id
          }
          const ress = await teacherClient.getPcById(params)
          res.rows[0].isClick = true
          this.teamsg = res.rows[0]
          this.teacherList = res.rows

          await this.goDet(res.rows[0])
        } else {
          this.teacherList = []
        }
      })
    // this.say();
  },
  methods: {
    gos() {
      this.$router.push(`/facultysixth`)
    },
    async getlist() {
      await teacherClient
        .teacherPageList(8, 1, undefined, 1)
        .then(async (res) => {
          if (res.rows) {
            for (const items of res.rows) {
              const params = {
                lectureId: items.id
              }
              const res = await teacherClient.getPcById(params)
              items.kindName = res.data?.kindName[0]
              items.kinds = res.data?.kindName[1]
              if (items.id == this.uid) {
                items.isClick = true
              } else {
                items.isClick = false
              }

              this.$forceUpdate
            }
            this.teacherList = res.rows

            this.total = res.total
          } else {
            this.teacherList = []
          }
        })
    },
    async goDet(item, index) {
      this.teachIndex = false
      this.teamsg = item
      console.log(this.teamsg)
      this.uid = item.id
      this.getlist()
      await this.getTeacher(item.id)
      const timer = setTimeout(() => {
        this.teachIndex = true
        clearTimeout(timer)
      }, 200)
    },
    goDetails() {
      const routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: this.teamsg.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    getTeacher(id) {
      const params = {
        lectureId: id
      }
      teacherClient.getPcById(params).then((res) => {
        this.kcList = res.data.kcList.map((item) => {
          return item.kcName
        })
        this.teacher = res.data.teacher
        this.kindName = res.data.kindName
        // this.selectStuQuestionsList();
        // this.getTerPcEvaluateList();
      })
    },
    tonext(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    toprev(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    }
    // say() {
    //   this.$nextTick(() => {
    //     var last = document.getElementById(
    //       `viesww${this.teacherList.length - 1}`
    //     );
    //     if (last) {
    //       let star = last.style.transform.indexOf("translateX(");
    //       let end = last.style.transform.indexOf("px)");
    //       let val = last.style.transform.substring(
    //         star + "translateX(".length,
    //         end
    //       );
    //       if (Number(val) < 1200 * 3) {
    //         this.$refs.nop.prev();
    //       }
    //       for (let i in this.teacherList) {
    //         var box = document.getElementById(`viesww${i}`);
    //         let starIndex = box.style.transform.indexOf("translateX(");
    //         let endIndex = box.style.transform.indexOf("px)");
    //         let nuw = box.style.transform.substring(
    //           starIndex + "translateX(".length,
    //           endIndex
    //         );
    //         let data = nuw ? Number(nuw) / 4 : null;
    //         if (data) {
    //           box.style.transform = `translateX(${data}px) scale(1) `;
    //           box.classList.add("is-animating");
    //         }
    //       }
    //     }
    //   });
    // },
  }
}
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}

@keyframes slidein {
  from {
    /* transform: translateX(-100%); */
    opacity: 0;
  }

  to {
    /* transform: translateX(0px); */
    opacity: 1;
  }
}

.teacher-enter-active {
  animation: slidein 600ms;
}

.teacher-leave-active {
  animation: slidein 100ms reverse;
}
</style>
<style lang="less" scoped>
.two-box {
  width: 100%;
  background: #f5f5f5;
}

.active {
  animation: slidein 1s;
}

.teacher-view {
  width: 1217px;
  margin: 108px auto 15px;
  background-color: #f5f5f5;

  .til {
    width: 1200px;
    margin: 30px auto 16px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .tea {
    position: relative;
    width: 1217px;
    height: 450px;
    padding-top: 6px;
    background-image: url('~@/assets/img/partner/teabac.png');
    background-size: 1230px 397px;
    background-position: top center;
    background-repeat: no-repeat;

    .teaDetail {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .teaDetail_imgBox {
        margin: 0px 20px 0 76px;
        width: 251.85px;
        height: 407.58px;

        img {
          width: 100%;
        }
      }

      .titleBox {
        .nameBox {
          margin-top: 56px;
          margin-bottom: 27px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .name {
            height: 29px;
            font-size: 22px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #0e8aed;
            margin-right: 17px;
          }

          .type {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 27px;
            border-radius: 14px 14px 14px 14px;
            opacity: 1;
            border: 1px solid #0f8cee;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #0d88ed;
          }
        }

        .content {
          width: 861px;
          height: 133px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000;

          /deep/ .text {
            height: 19px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .teaBox {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      width: 1214px;
      height: 170px;
      background: linear-gradient(82deg, #0270e8 0%, #21b4f5 100%);
      border-radius: 29px 0px 29px 0px;
      opacity: 1;

      .teaBox_item {
        width: 120px;
        height: 170px;
        padding-top: 26px;
        border-radius: 0px 0px 0px 0px;
        margin: 0 10px;

        &:hover {
          background: rgba(239, 246, 254, 0.2);
        }

        .itemimg {
          margin: 0 auto;
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .name {
          height: 21px;
          margin: 10px auto 0;
          font-size: 16px;
          text-align: center;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
