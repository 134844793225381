<template>
  <div class="seventhOpen">
    <!-- 套餐列表 -->
    <div class="datepage">
      <div class="colleges-title">
        <div class="text">
          <div>干货资料</div>
        </div>
        <div class="kind-list">
          <div v-for="(item, index) in majorList" :key="index" class="kind-item"
            :class="search.kind == item.pKind ? 'active' : ''" @click="school(item)">
            {{ item.pName }}
            <div class="choiceLefts" :v-if="search.kind == item.pKind" />
          </div>
        </div>
        <span class="more" @click="saveImg('/seventhPage/dataPackage')">查看更多<i class="iconfont icon-xiangyou"
            style="font-size:12px" /></span>
      </div>
      <div v-if="pageData.length > 0" class="datepage_list">
        <div v-if="loading" v-loading.lock="loading" style="width:100%;height:372px" />
        <div class="datepage_banner">
          <el-carousel indicator-position="none" arrow="never" height="284px">
            <el-carousel-item v-for="item in swiperList" :key="item.id">
              <img style="cursor: pointer;" class="swiper-img" :src="item.img" alt="" @click="goLink(item)">
            </el-carousel-item>
          </el-carousel>
        </div>
        <template v-for="(item, index) in pageData">
          <div v-if="index <= 5" :key="index" class="datepage_item">
            <div class="datepage_item_content">
              <div>
                <p class="datepage_item_content_title twoEllipsis">{{ item.title }}</p>
                <span class="datepage_item_content_details elp">
                  {{ item.kindName }}
                  <!-- <span style="margin-right:7px">强化</span> <span
                    style="margin-right:7px">剖析</span> 知识点</span> -->
                </span>
              </div>
              <!-- <img class="datepage_item_content_img" src="@/assets/img/homeSeventh/datepage2.png"> -->
            </div>
            <!-- <div class="datepage_item_line" /> -->
            <div class="datepage_item_list">
              <template v-for="(itemChild, index) in item.namesList">
                <div v-if="index <= 1" :key="index" class="li">
                  <img src="../../assets/img/Home/datepageIcon.png" alt="">
                  <span>{{ itemChild }}</span>
                </div>
              </template>
            </div>
            <div class="datepage_item_data">
              <span class="datepage_item_data_num">共<span style="color:#FF7B1C">{{ item.number }}份</span>资料</span>
              <el-button type="success" class="datepage_item_data_btn" round @click="godata(item)">查看资料</el-button>
            </div>
          </div>
        </template>

      </div>
      <div v-else class="datepage_list">
        <div class="queBox">
          <img class="que" src="@/assets/img/homeSeventh/que.png">
          <p>课程研发中~</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
import { selectPageListToIndex, getKindToKecheng, getMYTenantKindZyList, getTenantDocPackageZyList } from '@/api/home'
// import { selectPageList } from '@/api/task/answerClient'
import { AnswerClient } from '@/api/task/answerClient'
import { getClassCourseListById } from '@/api/mykecheng'
const answerClient = new AnswerClient()
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getInfo, getToken } from '@/api/cookies'
import {
  selectKindAllCollage,
  getSelectPcConfiguration
  // getSurfaceList,
  // selectCoursePages
} from '@/api/home'
import { tr } from 'date-fns/locale'
export default {
  components: {},
  data() {
    return {
      openClasslist: [], // 公开课
      collageList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null
      },
      loadingBtn: false,
      unPurchase: true,
      showMore: '在线题库',
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      loading: false,
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: 'first',
      swiperList: []
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.searchClick()
    // await this.getshoolList()
    await this.getKind()
    await this.selectPageList()
    await this.getPackageList()
    await this.getSwiperList()
    // this.getPaperMajor()
  },
  methods: {
    // 获取轮播数据
    async getSwiperList() {
      await getSelectPcConfiguration().then((res) => {
        this.swiperList = res.homeConfigurations.filter((s) => s.type == 1)
      })
    },

    // 项目专业
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code === 0) {
          this.collageList = res.data
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = []
        }
      })
    },
    school(val) {
      if (val) {
        this.search.kindList = val
        this.search.kindName = val?.pName
        this.search.kind = val?.pKind
      } else {
        this.search.kind = null
      }

      this.searchClick()
    },
    // 跳转试卷包和资料详情页
    godateDetail(row) {
      this.showMore = row.label
      // this.saveImg('/seventhPage/quesTionList')
    },
    handleClick() {
    },

    searchClick() {
      this.getPackageList()
      this.selectPageList()
    },
    /* 实用信息 */

    //  获取试卷包
    async selectPageList() {
      try {
        const res = await answerClient.getNewTenantPackageListToPc(
          8,
          1,
          this.search.kind,
          this.search.stageName,
          this.search.zyId
          /* state: 3, */
          // isShow: 1
        )
        this.tableData = res.rows
      } catch (error) {

      }
    },
    // 获取资料包
    async getPackageList() {
      this.loading = true
      try {
        const res = await dataClient.getNewPackageList(
          8,
          1,
          this.search.kind,
          this.search.stageName,
          this.search.zyId
          /* state: 3, */
          // isShow: 1
        )
        this.pageData = res.rows
        this.loading = false
      } catch (error) {

      }
    },
    /* 跳转试卷包详情页 */
    goPage(row, column, cell, event) {
      if (this.userInfo) {
        this.$router.push(`/all/examPack?packId=${row.id}`)
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    godata(row, column, cell, event) {
      if (this.userInfo) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: row.id,
            title: row.title,
            schoolId: row?.schoolId,
            isPurchase: row.isPurchase,
            num: row?.price
          }
        })
        //
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    /* =======公开课================= */
    // 获取项目专业
    async getKind() {
      getTenantDocPackageZyList().then(res => {
        if (res.code == 0) {
          this.majorList = res.data
          if (this.majorList.length > 0) {
            this.school(this.majorList[0])
          }
        } else {
          this.majorList = []
        }
      })

      // await getMYTenantKindZyList().then((res) => {
      //   if (res.code == 0) {
      //     this.zhuanList = res.data
      //     const oldList = []
      //     this.zhuanList.filter(item => {
      //       if (item.pKind == 3) {
      //         item.num = 1
      //         item.pName = '统招专升本'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 1) {
      //         item.num = 2
      //         item.pName = '自考专升本'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 9) {
      //         item.num = 3
      //         item.pName = '普通考研'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 6) {
      //         item.num = 4
      //         item.pName = '教师资格证'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 7) {
      //         item.num = 5
      //         item.pName = '计算机等级'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 5) {
      //         item.num = 6
      //         item.pName = '英语等级'
      //         oldList.push(item)
      //       }
      //     })
      //     const len = oldList.length
      //     for (let i = 0; i < len; i++) {
      //       for (let j = 0; j < i; j++) {
      //         if (oldList[j].num > oldList[i].num) {
      //           const temp = oldList[j]
      //           oldList[j] = oldList[i]
      //           oldList[i] = temp
      //         }
      //       }
      //     }
      //     this.majorList = oldList
      //   } else {
      //     this.zhuanList = []
      //   }
      // })


    },
    goLink(row) {

      window.open(row.link, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.seventhOpen {
  background-color: #f5f5f5;

  .datepage {
    width: 1200px;
    // display:flex;
    margin: 30px auto 6px;
    justify-content: space-betweent;
    align-items: start;
    background-color: #f5f5f5; // background-color: #4a6af0;

    .colleges-title {
      background-color: #f5f5f5;
      position: relative;
      margin: 4px 0 22px;
      height: 26px;
      display: flex;
      margin-bottom: 20px;
      width: 1200px;
      justify-content: start;

      .more {
        position: absolute;
        // margin-top:2px;
        right: 5px;
      }

      .kind-list {
        background-color: #f5f5f5;
        display: flex;
        height: 32px;
        margin-left: 40px;
        overflow: hidden;
        width: 718px;

        .kind-item {
          width: auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 40px;
          margin-top: 5px;
          position: relative;
          cursor: pointer;
          line-height: 26px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #333333;
          flex-shrink: 0;
        }

        .active {
          color: #00ab8c !important;

          .choiceLefts {
            position: absolute;
            bottom: 0px;
            left: calc(50% - 9px);
            width: 20px;
            height: 3px;
            background: #00ab8c;
            border-radius: 2px 2px 2px 2px;
          }
        }
      }

      .text {
        margin-right: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0px;
      }
    }

    .datepage_list {
      width: 1200px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-betweent;

      // padding-left: 4px;
      .datepage_banner {
        margin-right: 21px;
        margin-bottom: 24px;
        line-height: 1;

        .el-carousel {
          width: 586px;

          .swiper-img {
            width: 100%;
            height: 100% !important;
          }
        }
      }

      .queBox {
        width: 100%;
        width: 100%;
        height: 372px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .que {
          width: 112px;
        }

        p {
          // margin: auto;
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #777777;
        }
      }

      .datepage_item {
        background-color: #fff;
        padding: 10px;
        margin-right: 21px;
        margin-bottom: 24px;
        border-radius: 8px;
        transition: all 700ms;

        &:hover {
          transform: translateY(-6px);
          box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
        }

        &:nth-child(3),
        &:nth-child(7) {
          margin-right: 0;
        }

        width: 284px;
        height: 284px;

        .datepage_item_content {
          width: 100%;
          height: 146px;
          display: flex;
          background-image: url("../../assets/img/Home/datepagebg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .datepage_item_content_title {
            width: 203px;
            height: 46px;
            line-height: 23px;
            margin: 29px 0 9px 20px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }

          .datepage_item_content_img {
            width: 62px;
            height: 72px;
            margin-left: 22px;
          }

          .datepage_item_content_details {
            display: block;
            width: 140px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #90939a;
            margin-left: 20px;
          }
        }

        .datepage_item_line {
          margin-top: 20px;
          width: 246px;
          height: 0px;
          opacity: 1;
          border: 1px solid rgb(244, 244, 244);
        }

        .datepage_item_list {
          height: 50px;
          margin-top: 18px;
          padding-left: 4px;

          .li {
            display: flex;
            margin-bottom: 18px;

            img {
              display: inline-block;
              width: 10px;
              height: 10px;
              vertical-align: middle;
              margin-top: 3px;
              // line-height: 23px;
            }

            span {
              display: inline-block;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .datepage_item_data {
          margin-top: 18px;
          display: flex;
          justify-content: space-between;

          .datepage_item_data_num {
            height: 16px;
            font-size: 12px;
            margin-top: 6px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
          }

          .datepage_item_data_btn {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            width: 80px;
            padding-left: 12px;
            height: 32px;
            line-height: 0px;
            color: #00ab8c;
            border-color: #00ab8c;
            background-color: #fff;

            &:hover {
              color: #ffffff !important;
              background-color: #00ab8c !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
