<template>
  <div class="partnerOpen">
    <!-- 公开课 -->
    <div class="til">
      <div class="dis_flex_start">
        <div class="title">
          培训资讯
          <img src="@/assets/img/partner/corner.png" alt="" />
        </div>
        <div class="classify_list">
          <div class="classify_item" @click="choose(0)">全部</div>
          <div v-for="(items, indexs) in classifyList" :key="indexs" @click="choose(items)">
            <span style="color: #ddd;">丨</span> <span class="classify_item">{{ items.classifyName }} </span>
          </div>
        </div>
      </div>
      <div style="color: #90939A;" @click="saveImg('/allnews')">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="newsContent">
      <div class="newsContent_list">
        <div v-for="(item, index) in newsList" :key="index" class="newsContent_item" :class="`bac${index}`"
          @click="saveImg(`/partnerNewsxq/${item.id}`)">
          <div class="tips elp" :class="`tp${index}`">{{ item.classifyName }}</div>
          <div class="title twoEllipsis">
            {{ item.title }}
          </div>
          <div class="line"></div>
          <div class="content fireElp" v-html="item.content"></div>
        </div>
        <el-empty style="margin: 0 auto;" v-if="!newsList || newsList.length == 0" description="暂无数据" />
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import "@/assets/css/common.css";
import { Know } from "@/api/know";
const know = new Know();
import {
  selectPageListToIndex,
  getMYTenantKindZyList,
  selectNewPageListToIndex,
} from "@/api/home";
// import { selectPageList } from '@/api/task/answerClient'
import { getClassCourseListById } from "@/api/mykecheng";
import { getInfo } from "@/api/cookies";
import {
  selectKindAllCollage,
  queryAllArticle,
  selectClassifyList,
  // getSurfaceList,
  // selectCoursePages
} from "@/api/home";
export default {
  components: {},
  data() {
    return {
      newsList: [], // 培训资料
      classifyList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null,
      },
      classifyId: null,
      classifyName: null,
      loadingBtn: false,
      unPurchase: true,
      showMore: "在线题库",
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: "first",
    };
  },
  async created() {
    this.userInfo = await getInfo();
    await this.getList();
    await this.selectClassifyList();
  },
  methods: {
    // 项目专业
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code === 0) {
          this.collageList = res.data;
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = [];
        }
      });
    },
    show(item) {
      item.showlong = true;
    },
    hidden(item) {
      item.showlong = false;
    },
    choose(item) {
      if (item == 0) {
        this.classifyId = null;
        this.getList();
      } else {
        this.classifyId = item.id;
        this.classifyName = item.classifyName;
        this.getList();
      }
    },
    // 跳转试卷包和资料详情页
    godateDetail(row) {
      this.showMore = row.label;
    },
    async selectClassifyList(row) {
      try {
        const res = await selectClassifyList(1);
        console.log(res);
        if (res.code == 0) {
          this.classifyList = res.data;
        }
      } catch (error) { }
    },
    goMore() {
      if (this.showMore == "在线题库") {
        this.saveImg("/seventhPage/quesTionList");
      }
      if (this.showMore == "干货资料") {
        this.saveImg("/seventhPage/dataPackage");
      }
    },
    async getList() {
      try {
        const res = await queryAllArticle(undefined, 4, 1, this.classifyId);
        console.log(res);
        res.rows.forEach(e => {
        });
        this.newsList = res.rows;
      } catch (error) { }
    },
  },
};
</script>
<style lang="less" scoped>
.partnerOpen {
  background-color: #f5f5f5;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  .til {
    width: 1200px;
    margin: 30px auto 22px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    .classify_list {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 50px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #90939a;

      .classify_item {
        cursor: pointer;
        margin: 0 7px;

        &:hover {
          color: #333;
        }
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  /* 公开课 */
  .newsContent {
    width: 1200px;
    margin: 0px auto 0;

    .newsContent_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .newsContent_item {
        width: 282px;
        height: 286px;
        background: linear-gradient(225deg,
            rgba(212, 202, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%);
        margin-right: 24px;
        padding: 25px 32px 0 25px;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;

        &:last-child {
          margin-right: 0;
        }

        .tips {
          max-width: 72px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          margin-bottom: 16px;
          background: #b2c6de;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          font-size: 14px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: #ffffff;
        }

        .line {
          margin: 0 auto;
          width: 225px;
          height: 0px;
          opacity: 1;
          border: 1px dotted #EEEEEE;
        }

        .tp0 {
          background-color: #b2c6de;
        }

        .tp1 {
          background-color: #D4CAE1;
        }

        .tp2 {
          background-color: #B2C6DE;
        }

        .tp3 {
          background-color: #DCD8B0;
        }

        .title {
          width: 225px;
          height: 50px;
          line-height: 25px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-bottom: 18px;
        }

        .content {
          margin-top: 18px;
          width: 225px;
          height: 107px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 28px;
        }
      }

      .bac0 {
        background: linear-gradient(225deg,
            rgba(178, 198, 222, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%);
      }

      .bac1 {
        background: linear-gradient(225deg,
            rgba(212, 202, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%);
      }

      .bac2 {
        background: linear-gradient(225deg,
            rgba(178, 198, 222, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%);
      }

      .bac3 {
        background: linear-gradient(225deg,
            rgba(220, 216, 176, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
