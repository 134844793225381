<template>
  <!-- 精品课程-->
  <div class="p-t-20" v-if="list && list.length > 0">
    <div class="home-three-title" v-if="list && list.length > 0">
      <div class="text fourth-text">
        <img class="fourth-img" src="@/assets/img/homeFourth/open.png" />
        <div class="text-slot">
          <slot>精品课程</slot>
        </div>
      </div>
      <span class="more" @click="zhibokes">查看更多&nbsp;></span>
    </div>

    <!-- 精品课程  内容 -->
    <div class="live" v-if="list && list.length > 0">
      <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
        <!-- 活动详情 -->
        <img class="item-img" :src="item.img" />
        <div class="detail">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>

          <div class="item-tips elp">{{ item.pName }} · {{ item.kindName }}</div>

          <!-- 价格 -->
          <div class="flex-between-c price-box">
            <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
            <div class="tips">人气值 {{ item.clicks ? item.clicks : 0 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
let know = new Know()
import { selectCoursePages } from '@/api/home'
export default {
  components: {},
  data() {
    return {
      list: [], //公开课
    }
  },
  created() {
    know.getCourseList(10, 1).then((res) => {
      this.list = res.rows
    })
  },
  methods: {
    // 查看更多直播课按钮
    zhibokes() {
      let routeUrl = this.$router.resolve({
        path: '/jingpin/courer',
      })
      window.open(routeUrl.href, '_blank')
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        let routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`,
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        let routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`,
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        let routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`,
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        let routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`,
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        let routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`,
        })
        window.open(routeUrl.href, '_blank')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.home-three-title {
  padding-bottom: 30px;
  width: 1220px !important;
}
// 精品课程  内容
.live {
  width: calc(1220px + 25px);
  margin: 0px 0px 15px calc(50% - 610px);
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    margin-bottom: 24px;
    width: 224px;
    height: 271px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 25px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 8px 1px rgba(153, 153, 153, 0.20000000298023224);
    }
    .item-img {
      width: 224px;
      height: 126px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    .detail {
      padding: 8px 12px;
      .detail-title {
        height: 44px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .item-tips {
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9098ab;
        line-height: 12px;
        margin-top: 8px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
      .price-box {
        margin-top: 16px;
        .price {
          font-family: Microsoft YaHei-Regular;
          color: #ff5d51;
          font-size: 18px;
          line-height: 18px;
          font-weight: Bold;
          .price-icon {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
