<template>
  <div class="jingpin-box">
    <!--标题 最近直播 -->
    <div v-if="list && list.length > 0" class="home-three-title">
      <div class="text">
        <img class="item-img" src="@/assets/img/homeThree/live.png">
        <slot>最近直播</slot>
      </div>
      <span class="more" @click="zhibokes">查看更多&nbsp;></span>
    </div>
    <!-- 最近直播  内容 -->
    <div v-if="list && list.length > 0" class="jingpin">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="godetail(item)"
      >
        <!-- 直播时间 -->
        <div class="time-tips">
          <div class="iconfont icon-a-Group19" />
          <div class="text">
            {{ item.startTime | format("MM月dd日 HH:mm") }} 开播
          </div>
          <div class="line" />
        </div>
        <div class="item-content">
          <!-- 活动详情 -->
          <div class="tips">人气值 {{ item.clicks ? item.clicks : 0 }}</div>
          <img class="item-img" :src="item.img">
          <div class="detail">
            <div class="detail-title twoEllipsis">{{ item.title }}</div>
            <!-- 价格 -->
            <div class="tea-box">
              <span class="ter">讲师：{{ item.terName }}</span>
              <img class="videoBut" src="@/assets/img/homeThree/videoBut.png">
            </div>
            <!-- <div class="keep-learn" @click="godetail(item)">立即观看</div> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="ifHave"
      width="30%"
      center
    >
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { Know, courseCheckPlay } from '@/api/know'
const know = new Know()
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseById } from '@/api/home'
import { selectCoursePages } from '@/api/home'
import { getInfo } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [], // 公开课   ifHave: false,
      liveId: null,
      ifHave: false,
      liveshowName: '',
      live: null,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  created() {
    this.userInfo = getInfo()
    know.getLiveshowplaybackByTypes(5, 1).then((res) => {
      this.list = res.rows
    })
  },
  methods: {
    // 查看更多直播课按钮
    zhibokes() {
      this.$router.push({ name: '直播课' })
    },

    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true)
        return
      }
      this.liveId = item.liveId
      this.liveshowName = item.liveshowName
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase
        this.live = res.data.details
      })
      if (this.ifHave) {
        return
      }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        id: item.id,
        classId: this.classId,
        startTime: item.startTime,
        chapterName: item.title
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // });
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`,
            query: {
              id: item.id,
              startTime: item.startTime
            }
          })
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href
          } else {
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })

      /* this.$router.push({
        path: "/livebofang",
        query: {
          id: item.id,
        },
      }); */
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
// 最近直播  内容
.jingpin {
  // width: 66.2%;
  width: 1222px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
  }
  .item {
    opacity: 1;
    margin-right: 18px;
    overflow: hidden;
    &:last-child {
      margin-right: 0px;
      .time-tips {
        .line {
          display: none;
        }
      }
    }
    .time-tips {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      .iconfont {
        font-size: 16px;
        color: #ff5e51;
        margin-right: 9px;
      }
      .text {
        flex-shrink: 0;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .line {
        width: 29px;
        height: 2px;
        background: #ff5e51;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-left: 24px;
      }
    }
    .item-content {
      background: #ffffff;
      position: relative;
      width: 224px;
      height: 278px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      .tips {
        position: absolute;
        height: 26px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px 4px 4px 4px;
        padding: 0px 8px;
        top: 12px;
        left: 12px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 26px;
      }
      .item-img {
        width: 100%;
        height: 126px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        object-fit: cover;
      }
      .detail {
        background: #ffffff;
        padding: 12px;
        .detail-title {
          height: 48px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .tea-box {
          margin-top: 16px;
          height: 56px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #eeeeee;
          .videoBut {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.home-three-title {
  padding: 0px 0px 36px;
  width: 1220px;
}
.detail-ter {
  .ter-img {
    width: 24px;
    height: 24px;
  }
  .ter {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 12px;
  }
}
.keep-learn {
  position: absolute;
  bottom: 16px;
  width: 192px;
  height: 30px;
  background: #ff5e51;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.jingpin-box {
  padding-top: 30px;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
